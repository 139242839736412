import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import ButtonContained from './ButtonContained'
import { useTheme } from '@emotion/react'
import SearchInput from './SearchInput'

function CommonHeader({ title, handleSearch, onClick, placeholder, btnText, otherComponent, disableButton }) {
    const theme = useTheme()
    return (
        <>
            <Grid container spacing={2}>
                <Grid item paddingTop={'0px !important'} xs={12} sm={12} md={12}>
                    <Stack direction="row" p={"0px 0px 20px 0px"} alignItems="center" justifyContent="space-between" flexWrap="wrap" gap={2}>
                        <Typography variant="h6" fontWeight={"bold"} sx={{ color: theme?.palette?.primary?.main, }} >
                            {title}
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, flexGrow: 1 }} >
                            {otherComponent}
                        </Box>
                        <Box display={"flex"} alignItems="center" gap={1} flexWrap={'wrap'}>
                            {onClick ? <ButtonContained
                                disabled={disableButton}
                                onClick={onClick}
                                text={btnText || "Add"}
                                size="small"
                            /> : ''}
                            {handleSearch && <SearchInput
                                size="small"
                                variant="outlined"
                                placeholder={placeholder || "Search.."}
                                handleSearch={handleSearch}
                            />}
                        </Box>
                    </Stack>
                </Grid>
            </Grid >
        </>
    )
}

export default CommonHeader
