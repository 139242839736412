import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet, ApiPost } from "../../ApiSetUp/api";
import { api } from "../../ApiSetUp/Axios";

//create Subject
export const createSubject = createAsyncThunk("createSubject", async (body) => {
    return ApiPost(api?.createSubject, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//get Subjects
export const getSubjects = createAsyncThunk("getSubjects", async (body) => {
    return ApiPost(api?.getSubjects, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//get SubjectById
export const getSubjectById = createAsyncThunk("getSubjectById", async (body) => {
    return ApiGet(api?.getSubjectsById + body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//activeInactive Subject
export const activeInactiveSubject = createAsyncThunk("activeInactiveSubject", async (body) => {
    return ApiPost(api?.activeInactiveSubject, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//update Subject
export const updateSubject = createAsyncThunk("updateSubject", async (body) => {
    return ApiPost(api?.updateSubject, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

export const subjectSlice = createSlice({
    name: "subject",
    initialState: {
        subjects: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Create Subject
            .addCase(getSubjects.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubjects.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subjects = action?.payload?.data;
            })
            .addCase(getSubjects.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.error;
            })
    }
})

export default subjectSlice.reducer;
