import React from 'react'
import { Grid } from '@mui/material'
import TextFieldComponent from '../Common/TextFieldComponent';
import ErrorLog from '../Common/ErrorLog';
import CommonTextEditor from '../Common/CommonTextEditor';

function AddUpdateTopic({ data, handleChangeInput, subjectOptions, handleAutoCompleteChange, handleContentChange, errors }) {

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <TextFieldComponent
                        width="100%"
                        text="Topic Name"
                        placeholder="Enter Topic Name"
                        onChange={handleChangeInput}
                        name="topicName"
                        value={data?.topicName}
                        valid
                    />
                    {data?.topicName ? "" : <ErrorLog text={errors?.topicName} />}
                </Grid>
                <Grid item xs={12} sm={12}>
                    {/* <TextFieldComponent
                        width="100%"
                        text="Topic Description"
                        placeholder="Enter Topic Description"
                        onChange={handleChangeInput}
                        name="topicDescription"
                        value={data?.topicDescription}
                        valid
                        multiline
                        rows={4}
                    /> */}
                    <CommonTextEditor
                        text="Topic Description"
                        handleContentChange={handleContentChange}
                        value={data?.topicDescription}
                        valid
                    />
                    {(data?.topicDescription !== "<p><br></p>") ? "" : <ErrorLog text={errors?.topicDescription} />}
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                    <TextEditor
                        onChange={(value) => {
                            // Update the input for topic description
                            handleChangeInput({ target: { name: "topicDescription", value } });
                        }}
                        value={data?.topicDescription || ""}
                    />
                    {!data?.topicDescription ? <ErrorLog text={errors?.topicDescription} /> : null}
                </Grid> */}
            </Grid>
        </>
    )
}

export default AddUpdateTopic
