import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet, ApiPost } from "../../ApiSetUp/api";
import { api } from "../../ApiSetUp/Axios";

//get Lecture
export const getLecture = createAsyncThunk("getLecture", async (body) => {
    return ApiPost(api?.getLectures, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

// getLectures By Id
export const getLecturesById = createAsyncThunk("getLecturesById", async (body) => {
    return ApiGet(api?.getLecturesById + body)
        .then((response) => response?.data)
        .catch((error) => error)
})

// create Lecture

export const createLecture = createAsyncThunk("createLecture", async (body) => {
    return ApiPost(api?.createLecture, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//update Lecture    

export const updateLecture = createAsyncThunk("updateLecture", async (body) => {
    return ApiPost(api?.updateLecture, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

// update Topic
export const updateLectureSequence = createAsyncThunk("updateLectureSequence", async (body) => {
    return ApiPost(api?.updateLectureSequence, body)
        .then((response) => response?.data)
        .catch((error) => error)
})

export const LectureSlice = createSlice({
    name: "lecture",
    initialState: {
        lectures: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLecture.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLecture.fulfilled, (state, action) => {
                state.isLoading = false;
                state.lectures = action?.payload?.data;
            })
            .addCase(getLecture.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.error;
            })
    }

})

export default LectureSlice.reducer;