import React from "react";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
    return {
        main: {
            ".MuiInputBase-root": {
                paddingRight: "10px",
                borderRadius: 20,
                paddingLeft: 8,
            },
            ".MuiInputBase-input": {
                paddingLeft: 8,
                paddingRight: 8,
                height: '20px',
                width: '120px'
            }
        },
    };
});
export default function SearchInput({
    placeholder,
    variant,
    size,
    inputProps = {},
    autoFocus,
    startAdornment,
    endAdornment,
    fullWidth,
    ...props
}) {
    const { classes } = useStyles();
    const [searchTerm, setSearchTerm] = React.useState("");
    const [timer, setTimer] = React.useState(0);
    const WAIT_INTERVAL = 500;
    // const ENTER_KEY = 13;
    const handleClear = (e) => {
        setSearchTerm("");
        props.handleSearch && props.handleSearch("");
    };

    // React.useEffect(() => {
    //   if (!router.isReady) return;
    //   if () {
    //     setSearchTerm();
    //   }
    // }, [router.isReady, ]);

    return (
        <Box display="flex" alignItems="center">
            <TextField
                onChange={(ev) => {
                    setSearchTerm(ev.target.value);
                    let val = (ev.target.value || "").trim();
                    clearTimeout(timer);
                    setTimer(
                        setTimeout(() => {
                            props.handleSearch && props.handleSearch(val);
                        }, WAIT_INTERVAL)
                    );
                }}
                autoFocus={autoFocus}
                value={searchTerm}
                size={size || "medium"}
                type="text"
                inputProps={{ maxLength: 50, ...inputProps }}
                placeholder={placeholder || "Search"}
                InputProps={{
                    startAdornment: startAdornment ? (
                        startAdornment
                    ) : (
                        <>
                            <InputAdornment
                                sx={{ borderRight: 1, borderColor: "text.primary" }}
                                position="start"
                                variant="outlined"
                            >
                                <IconButton style={{ padding: 0 }}>
                                    <SearchOutlinedIcon color="primary" />
                                </IconButton>
                            </InputAdornment>
                            <Divider orientation="vertical" flexItem />
                        </>
                    ),
                    endAdornment: endAdornment ? (
                        endAdornment
                    ) : (
                        <InputAdornment position="end" sx={{ opacity: searchTerm ? 1 : 0 }}>
                            <IconButton style={{ padding: 2 }} onClick={handleClear}>
                                <ClearIcon fontSize="small" color="disabled" />
                            </IconButton>
                        </InputAdornment>
                    ),
                    sx: { bgcolor: "#fff" },
                }}
                variant={variant || "standard"}
                fullWidth={fullWidth}
                className={classes.main}
            />
        </Box>
    );
}