import { Badge, Box, Grid, IconButton, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import CommonHeader from '../../Components/Common/CommonHeader';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import StyledTableRow from '../../Components/Common/StyledTableRow';
import PaperContainer from '../../Components/Common/StyledTableCell';
import StyledTableCell from '../../Components/Common/StyledTableCell';
import CustomTooltip from '../../Components/Common/CustomTooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DataNotFound from '../../Components/Common/dataNotFound';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { createTopic, getTopic, getTopicsById, updateTopic, updateTopicSequence } from '../../Redux/Slices/topicSlice';
import ErrorHandler, { trimAndRemoveExtraSpaces } from '../../Utils/handler';
import CustomDialog from '../../Components/Common/CustomDialog';
import ButtonContained from '../../Components/Common/ButtonContained';
import AddUpdateTopic from '../../Components/Topic/AddUpdateTopic';
import { getSubjects } from '../../Redux/Slices/subjectSlice';
import CustomPagination from '../../Components/Common/CustomPagination';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import WidgetLoader from '../../Components/Common/widgetLoader';
import { toast } from 'react-toastify';
import AutoCompleteSearch from '../../Components/Common/AutoCompleteSearch';
import SwapVerticalCircleRoundedIcon from '@mui/icons-material/SwapVerticalCircleRounded';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ReadMore from '../../Components/Common/ReadMore';
function Topic() {
    //Hooks
    const theme = useTheme();
    const dispatch = useDispatch()

    //States
    const [isOpen, setIsOpen] = React.useState(false);
    const [isEdit, setisEdit] = React.useState(false);
    const [topicDataList, setTopicDataList] = React.useState([])
    const [errors, setErrors] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [data, setData] = React.useState({ topicDescription: "<p><br></p>", });
    const [subjects, setSubjects] = React.useState([])
    const [isSequence, setIsSequence] = React.useState({ idx: "", open: false })

    const subjectOptions = subjects?.length > 0 ? subjects?.map((subject) => subject?.subjectName) : []

    //  Validations 
    const validation = () => {
        let isValid = true;
        let error = {};

        if (!data?.subjectName) {
            isValid = false;
            error.subjectName = '*Subject name is required';
        }
        if (!data?.topicName) {
            isValid = false;
            error.topicName = '*Topic name is required';
        }
        if (data?.topicDescription === "<p><br></p>") {
            isValid = false;
            error.topicDescription = '*Description is required';
        }
        setErrors(error);
        return isValid;
    }

    //  Handlers 

    const handleClear = () => {
        setIsOpen(false);
        setisEdit(false);
        setData({ ...data, topicDescription: "<p><br></p>", topicName: "" });
        setErrors({});
        setIsSequence({ idx: "", open: false });
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAutoCompleteChange = (name, newValue) => {
        setData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };

    const handleContentChange = (content) => {
        setData(prevState => ({
            ...prevState,
            topicDescription: content
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const getSubjectId = subjects?.filter((subject) => {
        if (subject?.subjectName === data?.subjectName) {
            return subject;
        }
    })
    console.log(getSubjectId, "getSubjectId")
    //API Calling
    const createUpdateTopic = async () => {
        if (validation()) {
            dispatch(showLoader(true))
            try {
                let body = {
                    "subjectId": getSubjectId?.[0]?._id,
                    "topicName": trimAndRemoveExtraSpaces(data?.topicName),
                    "topicDescription": data?.topicDescription,
                }
                if (isEdit && data?.id) {
                    body._id = data?.id
                    body.sequence = data?.sequence
                }
                const fn = isEdit ? updateTopic : createTopic
                const result = await dispatch(fn(body))
                const error = ErrorHandler(result)
                if (error) {
                    toast.success(result?.payload?.message);
                    _getTopics()
                    handleClear()
                }
            } catch (error) {
                toast.error(error);
                console.log(error)
            } finally {
                dispatch(showLoader(false))
            }
        }
    }
    const validateSequence = (previousSequence) => {
        if (data?.sequence !== previousSequence) {
            return true
        } else {
            toast.error("Sequence you entered is same !");
            handleClear()
            return false
        }
    }
    const _updateTopicSequence = async (id) => {
        setLoading(true);
        try {
            const result = await dispatch(updateTopicSequence({ _id: id, sequence: +data?.sequence, }))
            const error = ErrorHandler(result)
            if (error) {
                toast.success(result?.payload?.message);
                _getTopics()
                handleClear()
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false)
        }
    }

    const _getTopics = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: searchText || "",
            subjectId: getSubjectId?.[0]?._id
        };
        try {
            const result = await dispatch(getTopic(body))
            const error = ErrorHandler(result)
            if (error) {

                setTopicDataList(result?.payload?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    console.log(data, "data")

    const _getSubjects = async () => {
        try {
            const result = await dispatch(getSubjects())
            const error = ErrorHandler(result)
            if (error) {
                const config = result?.payload?.data?.response
                if (config?.length > 0) {
                    setSubjects(config)
                    setData({
                        ...data,
                        subjectName: config?.[0]?.subjectName || '',
                    });
                }

            }
        } catch (error) {
            console.log(error)
        }
    }

    const _getTopicsById = useCallback(async (id) => {
        dispatch(showLoader(true));
        try {
            const body = `/${id}`
            const result = await dispatch(getTopicsById(body))
            const error = ErrorHandler(result)
            if (error) {
                const topic = result?.payload?.data;
                setData({
                    id: topic?._id,
                    subjectName: topic?.subjectDetails?.subjectName,
                    topicName: topic?.topicName,
                    topicDescription: topic?.topicDescription,
                    sequence: topic?.sequence,
                });
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false));
        }
    }, [dispatch])

    //  Effect 

    useEffect(() => {
        _getSubjects()
    }, [])

    useEffect(() => {
        if (data?.subjectName) _getTopics()
    }, [data?.subjectName, rowsPerPage, page, searchText])

    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12}>
                    <CommonHeader
                        title="Topic List"
                        onClick={() => setIsOpen(true)}
                        btnText="Add Topic"
                        disableButton={(getSubjectId?.[0]?._id) ? false : true}
                        handleSearch={(e) => { setPage(1); setSearchText(e); }}
                        otherComponent={
                            <>
                                <Box width={'250px'}>
                                    <AutoCompleteSearch
                                        width="100%"
                                        textLabel="Subject"
                                        placeholder="Search Subject"
                                        name="subjectName"
                                        handleChange={(newValue) => handleAutoCompleteChange("subjectName", newValue)}
                                        options={subjectOptions}
                                        value={data?.subjectName}
                                        isSearchIcon={false}
                                    />
                                </Box>
                            </>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer sx={{ width: "100%" }}>
                        {!loading ? (
                            <Table sx={{ minWidth: 700 }}>
                                <TableHead component={PaperContainer} >
                                    <StyledTableRow>
                                        <StyledTableCell align='left'>No.</StyledTableCell>
                                        <StyledTableCell align='left'>Sequence</StyledTableCell>
                                        <StyledTableCell align='left'>Topic Name</StyledTableCell>
                                        <StyledTableCell align="left">Topic Description</StyledTableCell>
                                        <StyledTableCell align="left">Created At</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {topicDataList?.response?.length > 0 ? topicDataList?.response?.map((topic, index) => {
                                        return (
                                            <StyledTableRow key={topic?._id}>
                                                <StyledTableCell component="th" scope="row">{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <UpdateSequenceComponent
                                                        isSequence={isSequence}
                                                        setIsSequence={setIsSequence}
                                                        index={index}
                                                        setData={setData}
                                                        data={data}
                                                        value={topic}
                                                        handleChangeInput={handleChangeInput}
                                                        onClick={() => { if (validateSequence(topic?.sequence)) _updateTopicSequence(topic?._id) }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>{topic?.topicName} </StyledTableCell>
                                                <StyledTableCell><ReadMore content={topic?.topicDescription} maxLength={100} /> </StyledTableCell>
                                                <StyledTableCell>{topic?.createdAt ? new Date(topic.createdAt).toLocaleDateString() : 'N/A'}</StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                                        <CustomTooltip title={'Edit'}>
                                                            <IconButton size='small' aria-label="edit" sx={{ backgroundColor: theme.palette.bgInfo.secondary, color: theme.palette.bgInfo.main, }}
                                                                onClick={() => {
                                                                    setIsOpen(true);
                                                                    setisEdit(true);
                                                                    setIsSequence({ idx: "", open: false }); setData({ ...data, sequence: "" });
                                                                    _getTopicsById(topic?._id)
                                                                }}>
                                                                <EditOutlinedIcon fontSize='small' />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                    </Box>

                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }) :
                                        <StyledTableRow>
                                            <TableCell colSpan={12}>
                                                <DataNotFound title="No Topic Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                            </TableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        ) : (
                            <WidgetLoader />
                        )}
                    </TableContainer>
                </Grid>
                {topicDataList?.count > 0 &&
                    <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(topicDataList?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />

                    </Grid>
                }
                <CustomDialog
                    isOpen={isOpen}
                    handleClose={handleClear}
                    title={`${isEdit ? "Update" : "Add"} Topic`}
                    width='130vh'
                    action={
                        <>
                            <Stack direction="row" justifyContent="flex-end" mr={2} >
                                <ButtonContained height={'35px'} padding={'6px 10px'} text={`${isEdit ? "Update" : "Add"} Topic`} onClick={createUpdateTopic} />
                            </Stack>
                        </>
                    }
                >
                    <AddUpdateTopic
                        handleClose={handleClear}
                        isEdit={isEdit}
                        data={data}
                        handleChangeInput={handleChangeInput}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        handleContentChange={handleContentChange}
                        subjectOptions={subjectOptions}
                        errors={errors}
                    />
                </CustomDialog>
            </Grid>
        </>
    )
}

const UpdateSequenceComponent = ({ isSequence, handleChangeInput, setIsSequence, setData, value, data, index, onClick }) => {
    return (
        <Box display={'flex'} alignItems={'center'} width={'70px'}>
            {(isSequence?.open && isSequence?.idx === index) ?
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2}>
                    <Input defaultValue={value?.sequence} inputProps={{ 'aria-label': 'description' }} name='sequence' onChange={handleChangeInput} />
                    <TaskAltIcon fontSize='small' color='success' sx={{ cursor: "pointer", }} onClick={onClick} />
                </Box>
                :
                <CustomTooltip title={'Update Sequence'}>
                    <Badge badgeContent={value?.sequence} color="warning" sx={{
                        "& .MuiBadge-badge": {
                            height: '17px',
                            fontSize: '10px'
                        }
                    }}>
                        <SwapVerticalCircleRoundedIcon color='primary' fontSize='medium' onClick={() => { setIsSequence({ idx: index, open: true }); setData({ ...data, sequence: value?.sequence }) }} sx={{ cursor: 'pointer' }} />
                    </Badge>
                </CustomTooltip>
            }
        </Box>
    )

}

export default Topic
