import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet, ApiPost } from "../../ApiSetUp/api";
import { api } from "../../ApiSetUp/Axios";


// get Topic
export const getTopic = createAsyncThunk("getTopic", async (body) => {
    console.log(body, 'bodyTopics')
    return ApiPost(api?.getTopics, body)
        .then((response) => response?.data)
        .catch((error) => error)
})

// create Topic
export const createTopic = createAsyncThunk("createTopic", async (body) => {
    return ApiPost(api?.createTopic, body)
        .then((response) => response?.data)
        .catch((error) => error)
})

// update Topic
export const updateTopic = createAsyncThunk("updateTopic", async (body) => {
    return ApiPost(api?.updateTopic, body)
        .then((response) => response?.data)
        .catch((error) => error)
})

// update Topic
export const updateTopicSequence = createAsyncThunk("updateTopicSequence", async (body) => {
    return ApiPost(api?.updateTopicSequence, body)
        .then((response) => response?.data)
        .catch((error) => error)
})

// getTopics By Id
export const getTopicsById = createAsyncThunk("getTopicsById", async (body) => {
    return ApiGet(api?.getTopicsById + body)
        .then((response) => response?.data)
        .catch((error) => error)
})

export const topicSlice = createSlice({
    name: "topic",
    initialState: {
        topics: [],
        error: null,
        isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTopic.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTopic.fulfilled, (state, action) => {
                state.isLoading = false;
                state.topics = action?.payload?.data;
            })
            .addCase(getTopic.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.error;
            })
    }
})

export default topicSlice.reducer;