import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, InputLabel, Typography } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => {
  return {
    searchInput: {
      // border: 'none',
      // '& .MuiOutlinedInput-notchedOutline': {

      // }
      height: '40px',
      width: '40px',
      borderTop: '1px solid #dddddd',
      borderRight: 'none',
      borderBottom: '1px solid #dddddd',
      borderLeft: '1px solid #dddddd',
      borderRadius: '4px 0px 0px 4px',
      textAlign: 'center'
    },
  };
});

export default function AutoCompleteSearch(props) {
  const {
    width,
    text,
    valid,
    options,
    value,
    placeholder,
    handleChange,
    name,
    fontWeight,
    disabled,
    freeSolo = true,
    blurOnSelect,
    loadData,
    onBlur,
    searchValue,
    handleSearch,
    isSearchIcon,
    textLabel,
  } = props;

  const { classes } = useStyles();
  console.log(value, 'value topic')
  return (
    <>
      {text && (
        <Box
          // mt={1.5}
          mb={1}
          display="flex"
          fontSize="12px"
          flexDirection={"row"}
        >
          <InputLabel
            sx={{
              fontWeight: fontWeight,
              fontSize: "14px",
              marginRight: "2px",
            }}
          >
            {text}
          </InputLabel>
          {valid && (
            <Typography color="#EF627A" component={"caption"} variant={"body2"}>
              *
            </Typography>
          )}
        </Box>
      )}
      <Box
        display="flex"
        className="search_filed"
        borderRadius={1}
        alignItems={'center'}
      >
        {isSearchIcon && <Box className={classes.searchInput} >
          <SearchOutlinedIcon color="primary" sx={{ height: '40px' }} />
        </Box>}
        <Autocomplete
          size="small"
          disabled={disabled ? disabled : ""}
          autoHighlight={false}
          disablePortal
          blurOnSelect={blurOnSelect}
          options={options || []}
          onChange={(event, newValue) => handleChange(newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          disableClearable
          ListboxProps={{
            style: {
              maxHeight: "180px",
              border: "1px solid white",
            },
            onScroll: (event) => {
              event.stopPropagation();
              const listboxNode = event.currentTarget;
              const savedScrollTop = listboxNode.scrollTop;
              const diff = Math.round(
                listboxNode.scrollHeight - savedScrollTop
              );
              if (diff - 25 <= listboxNode.clientHeight) {
                const returnScrollTop = () => {
                  setTimeout(() => {
                    listboxNode.scrollTop = savedScrollTop;
                  }, 200);
                };
                if (loadData) {
                  loadData(0, searchValue);
                  returnScrollTop();
                }
              }
            },
          }}
          value={value || ''}
          sx={{ width: width, }}
          freeSolo={freeSolo}
          renderInput={(params) => (
            <TextField
              {...params}
              onBlur={onBlur}
              placeholder={placeholder}
              onChange={handleSearch}
              name={name}
              disabled={disabled ? disabled : ""}
              value={searchValue}
              label={textLabel ? textLabel : ""}
              sx={{
                backgroundColor: "white",
                borderRadius: "20px",
              }}

            />
          )}
        />
      </Box>
    </>
  );
}
