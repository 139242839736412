import * as React from 'react';
import { useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, } from '@mui/material';
import cleanHtml from '../../lib/cleanHtml';

export default function CustomAlertPopup({
    isOpen,
    handleClose,
    title,
    onSave,
    subText,
    btnText,
    maxWidth
}) {
    const theme = useTheme()
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth || "sm"}
            fullWidth
        >
            <DialogTitle sx={{ m: 0.5, p: 0.5, color: theme?.palette?.primary?.main, borderBottom: '1px solid lightgray' }}>
                <Typography fontSize={'18px'}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: '2px 13px' }}>
                <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: cleanHtml(subText, false) }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={onSave} autoFocus>{btnText}</Button>
            </DialogActions>
        </Dialog>
    );
}
