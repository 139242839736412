import React from 'react'
import { Box, Button, InputLabel, Typography } from '@mui/material';
import { Delete, Visibility } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import CustomDialog from '../Common/CustomDialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { BucketURL } from '../../ApiSetUp/api';
import CustomAlertPopup from './CustomAlertPopup';


function FilePreviewModel({ isOpen, handleClose, accept, name, valid, disabled, label, subText, fileLink, deleteFile, fileName, title, btnText, onClick, onChange, id, isPdf, htmlFor, isMultipleUpload, multiplefilesData, isSingleUpload, setData, data }) {
    const theme = useTheme()

    const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(false);
    const [selectedFileURL, setSelectedFileURL] = React.useState('');
    const [selectedFileId, setSelectedFileId] = React.useState('');
    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'flex-end', }} flexWrap={'wrap'}>
                <input
                    accept={accept}
                    style={{ display: 'none' }}
                    id={id}
                    onChange={onChange}
                    disabled={disabled || false}
                    type="file"
                    name={name}
                    onClick={event => event.target.value = null}
                    multiple={isMultipleUpload}
                />
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: "14px", color: '#666666', whiteSpace: "nowrap", marginBottom: "8px", cursor: 'text' }}>
                        {label}
                        {valid && (<Typography color="#EF627A" component="caption" variant="body2" marginLeft={'4px'}>*</Typography>)}
                        {subText && <Typography color={'Gray'} component="caption" fontSize={'10px'} marginLeft={'4px'} variant="body2">
                            {subText}
                        </Typography>}
                    </InputLabel>
                    <label htmlFor={htmlFor}>
                        <Button variant="contained" component="span" size='small' disabled={disabled || false} >
                            <CloudUploadIcon sx={{ pr: 1 }} />
                            {btnText}
                        </Button>
                    </label>
                </Box>
                {fileName &&
                    <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flexWrap: 'wrap' }} flexWrap={'wrap'}>
                        <Box sx={{ display: 'flex', gap: '10px', color: theme.palette.primary.main }} flexWrap={'wrap'}>
                            <Visibility sx={{ cursor: "pointer" }} onClick={onClick} />
                            <Typography variant="body1" color="textSecondary" maxWidth={'180px'} noWrap>{fileName}</Typography>
                            <Delete onClick={() => { setDeleteAlertOpen(true); }} sx={{ cursor: "pointer" }} />
                        </Box>
                        {isOpen && <CustomDialog
                            isOpen={isOpen}
                            handleClose={handleClose}
                            title={title}
                            width={'100%'}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                {isPdf ? <iframe
                                    src={data?.fileData ? fileLink : BucketURL + fileLink}
                                    style={{ border: 'none', width: '-webkit-fill-available' }}
                                    height={'450px'}
                                    title={fileLink}
                                    width={'100%'}
                                /> :
                                    <img
                                        src={data?.fileData ? fileLink : BucketURL + fileLink}
                                        style={{ border: 'none', width: '-webkit-fill-available' }}
                                        // height={'450px'}
                                        title={fileLink}
                                        width={'100%'}
                                    />
                                }
                            </Box>
                        </CustomDialog>}
                    </Box>}
                <Box>
                    {isMultipleUpload && multiplefilesData?.length > 0 &&
                        multiplefilesData?.map((fileData, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', gap: '10px', color: theme.palette.primary.main }}>
                                        <Visibility onClick={() => onClick(index)} sx={{ cursor: "pointer" }} />
                                        <Typography variant="body1" color="textSecondary" maxWidth={'180px'} noWrap> {fileData?.fileName}</Typography>
                                        <Delete onClick={() => { setDeleteAlertOpen(true); setSelectedFileURL(fileData?.fileUrl); setSelectedFileId(fileData?.id) }} sx={{ cursor: "pointer" }} />
                                    </Box>
                                    {isOpen === index && (
                                        <CustomDialog isOpen={true} handleClose={handleClose} title={fileData?.fileName} width={'100%'} >
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <img
                                                    src={data?.multiplefileList?.length > 0 ? fileData?.fileUrl : BucketURL + fileData?.fileUrl}
                                                    style={{ borderRadius: '10px', border: 'none', width: '-webkit-fill-available' }}
                                                    height={'450px'}
                                                    alt={fileData?.fileUrl}
                                                />
                                            </Box>
                                        </CustomDialog>
                                    )}
                                </Box>
                            );
                        })
                    }
                </Box>
            </Box>
            {deleteAlertOpen && <CustomAlertPopup
                isOpen={deleteAlertOpen}
                handleClose={() => setDeleteAlertOpen(false)}
                title={'Delete Confirmation'}
                subText={'Are you sure you want to delete image?'}
                btnText={'Delete'}
                onSave={() => { deleteFile(selectedFileURL ? selectedFileURL : fileLink, isMultipleUpload, isMultipleUpload ? selectedFileId : selectedFileId); setDeleteAlertOpen(false) }}
                maxWidth={'xs'}
            />}
        </>
    )
}

export default FilePreviewModel;
