import React, { useEffect } from 'react'
import CommonHeader from '../../Components/Common/CommonHeader';
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import StyledTableRow from '../../Components/Common/StyledTableRow';
import StyledTableCell from '../../Components/Common/StyledTableCell';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PaperContainer from '../../Components/Common/StyledTableCell';
import DataNotFound from '../../Components/Common/dataNotFound';
import { useTheme } from '@emotion/react';
import { getUserQueries, } from '../../Redux/Actions/action';
import ErrorHandler from '../../Utils/handler';
import { useDispatch } from 'react-redux';
import CustomPagination from '../../Components/Common/CustomPagination';
import WidgetLoader from '../../Components/Common/widgetLoader';
import ReadMore from '../../Components/Common/ReadMore';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CustomTooltip from '../../Components/Common/CustomTooltip';
import CustomDialog from '../../Components/Common/CustomDialog';
import { BucketURL } from '../../ApiSetUp/api';
function UserQuery() {
    //hooks
    const theme = useTheme()
    const dispatch = useDispatch()
    //state

    const [userQueries, setUserQueries] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [viewDoc, setViewDoc] = React.useState({ fileUrl: "", open: false })

    //handler 

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    // API Calling
    const _getNotes = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: searchText || "",
        };
        try {
            const result = await dispatch(getUserQueries(body));
            const error = ErrorHandler(result)
            if (error) {
                setUserQueries(result?.payload?.data)
            }
        }
        catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        _getNotes()
    }, [rowsPerPage, page, searchText])
    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12}>
                    <CommonHeader title="User Queries" handleSearch={(e) => { setPage(1); setSearchText(e); }} />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer sx={{ width: "100%" }}>
                        {!loading ? (
                            <Table sx={{ minWidth: 700 }}>
                                <TableHead component={PaperContainer}>
                                    <StyledTableRow>
                                        <StyledTableCell align='left'>No.</StyledTableCell>
                                        <StyledTableCell align='left'>Title</StyledTableCell>
                                        <StyledTableCell align='center'>Description</StyledTableCell>
                                        <StyledTableCell align='left'>User Name</StyledTableCell>
                                        <StyledTableCell align='left'>Email</StyledTableCell>
                                        <StyledTableCell align='left'>Phone Number</StyledTableCell>
                                        <StyledTableCell align="left">View Document</StyledTableCell>
                                        <StyledTableCell align='left'>Created At</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {userQueries?.response?.length > 0 ? userQueries?.response?.map((query, index) => {
                                        const user = query?.userDetails;
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{query?.title}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">  <ReadMore content={query?.description} maxLength={100} /> </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">  {user?.firstName} {user?.lastName} </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">  {user?.email} </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">  {user?.phoneNumber} </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    {query?.image ? <CustomTooltip title={'View Document'}>
                                                        <IconButton size='small' onClick={() => setViewDoc({ fileUrl: query?.image, open: true })} sx={{ backgroundColor: theme.palette.info.secondary, color: theme.palette.info.main, }}>
                                                            <VisibilityOutlinedIcon fontSize='small' />
                                                        </IconButton>
                                                    </CustomTooltip> : "-"}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">  {query?.createdAt ? new Date(query?.createdAt).toLocaleDateString() : 'N/A'} </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }) : (
                                        <StyledTableRow>
                                            <TableCell colSpan={12}>
                                                <DataNotFound title="No Queries Found!" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                            </TableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        ) : (
                            <WidgetLoader />
                        )}
                    </TableContainer>
                </Grid>
                {userQueries?.count > 0 &&
                    <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(userQueries?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />

                    </Grid>
                }

                {viewDoc?.open && <CustomDialog
                    isOpen={viewDoc?.open}
                    handleClose={() => setViewDoc({ fileUrl: "", open: false })}
                    title={"Document"}
                    width={'100%'}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <img
                            src={BucketURL + viewDoc?.fileUrl}
                            style={{ borderRadius: '10px', border: 'none' }}
                            height={'450px'}
                            width={'100%'}
                            title='fileUrl'
                        />
                    </Box>
                </CustomDialog>}

            </Grid>
        </>
    )
}

export default UserQuery
