import { configureStore } from '@reduxjs/toolkit'
import loaderSlice from './Slices/loaderSlice'
import subjectSlice from './Slices/subjectSlice'
import topicSlice from './Slices/topicSlice'
import LectureSlice from './Slices/LectureSlice'

const store = configureStore({
    reducer: {
        loader: loaderSlice,
        subject: subjectSlice,
        topic: topicSlice,
        lecture: LectureSlice,
    }
})

export default store