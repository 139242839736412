import { useTheme } from '@emotion/react'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Regex } from '../../Regex/regex'
import { adminLogin } from '../../Redux/Actions/action'
import ErrorHandler from '../../Utils/handler'
import sessionStoreUtil from '../../ApiSetUp/LocalStorage'
import TextFieldComponent from '../../Components/Common/TextFieldComponent'
import ErrorLog from '../../Components/Common/ErrorLog'

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()

    const [data, setData] = useState({})
    const [error, setError] = useState(false)
    const [loading, setLoading] = React.useState(false)

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const validation = () => {
        let { email, password } = data;
        let errors = {};
        let isValid = true;

        if (!email) {
            isValid = false
            errors.email = "*Enter email address "
        } else if (email && !email?.match(Regex.emailRegex)) {
            isValid = false
            errors.email = "*Enter valid email address"
        }

        if (!password) {
            isValid = false
            errors.password = "*Enter password"
        } else if (password && !password?.match(Regex.passwordRegex)) {
            isValid = false
            errors.password = "*Password is invalid. It must be at least 8 characters long and contain at least 1 uppercase letter.";
        }
        console.log(error, "error")
        setError(errors)
        return isValid
    }

    const login = async () => {
        setLoading(true)
        if (validation()) {
            let body = {
                email: data?.email,
                password: data?.password
            }
            const result = await dispatch(adminLogin(body));
            console.log(result, 'result')
            const error = ErrorHandler(result)
            if (error) {
                sessionStoreUtil.setLSItem("authtoken", result?.payload?.data?.authToken)
                navigate('/dashboard')
            }
            console.log('Login successful')
        }
        setLoading(false)
    }

    return (
        <>

            <>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bgcolor={theme.palette.primary.main}
                    height="100vh"
                    padding="0px 15px"
                >
                    <Grid container xs={12} sm={12} md={5} lg={5} xl={5}
                        bgcolor={'white'}
                        padding={"15px 20px"}
                        spacing={2}
                        borderRadius={5}
                        margin={"0px"}
                    >
                        <Grid item xs={12} justifyContent="center" alignItems={'center'} textAlign={'center'}>
                            <img style={{ paddingBottom: '10px', width: '150px', height: 'auto' }} src='Images/Clavivle_logo.png' alt='logo' />
                            <Typography variant="h5" color="primary" >Login</Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <TextFieldComponent
                                label="Email"
                                size="small"
                                width="100%"
                                placeholder="Enter your email here"
                                name='email'
                                value={data?.email}
                                fullWidth
                                onChange={(e) => handleChange(e)}
                                type="email"
                                required
                            />
                            {data?.email && data?.email?.match(Regex.emailRegex) ? "" : <ErrorLog text={error?.email} />}

                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Password"
                                size="small"
                                width="100%"
                                placeholder="Enter your password here"
                                name='password'
                                showPasswordToggle={true}
                                value={data?.password}
                                fullWidth
                                onChange={(e) => handleChange(e)}
                                type="password"
                                required
                            />
                            {data?.password && data?.password?.match(Regex.passwordRegex) ? "" : <ErrorLog text={error?.password} />}

                        </Grid>
                        <Grid item xs={12}>
                            <Box paddingTop={3} textAlign={'center'}>
                                <Button variant="contained" color="primary" onClick={login} endIcon={loading && <CircularProgress color="inherit" size='18px' />}>
                                    Login
                                </Button>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </>

        </>
    )
}

export default Login