import React from 'react'
import { Box } from '@mui/material'
import Layout from './Layout'
import { useLocation } from 'react-router-dom'


const MainLayout = ({ children }) => {
    const location = useLocation()
    return (
        <Box>
            {(location?.pathname !== "/login" && location?.pathname !== "/singUp") ? <Layout children={children} /> : children}
        </Box>
    )
}

export default MainLayout