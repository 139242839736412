import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import TextFieldComponent from '../Common/TextFieldComponent'
import ErrorLog from '../Common/ErrorLog'
import FilePreviewModel from '../Common/FilePreviewModel'
import CommonTextEditor from '../Common/CommonTextEditor'

function AddUpdateNote({ data, errors, setData, handleChangeInput, handleContentChange, handleImageUpload, handleMultipleImageUpload, deleteFile, }) {
    const [isOpen, setIsOpen] = useState(false);
    const [previewFileIndex, setPreviewFileIndex] = useState(null);

    const handleOpenPreview = (index) => {
        setPreviewFileIndex(index);
    }

    const handleClosePreview = (index) => {
        setPreviewFileIndex(null);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextFieldComponent
                        width="100%"
                        text="Title"
                        placeholder="Enter Title"
                        onChange={handleChangeInput}
                        name="title"
                        value={data?.title}
                        valid
                    />
                    {data?.title ? "" : <ErrorLog text={errors?.title} />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFieldComponent
                        width="100%"
                        text="Price"
                        placeholder="Enter Price"
                        onChange={handleChangeInput}
                        name="price"
                        value={data?.price}
                        type="number"
                        valid
                    />
                    {data?.price ? "" : <ErrorLog text={errors?.price} />}
                    {data?.price > 0 ? "" : <ErrorLog text={errors?.priceZero} />}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextFieldComponent
                        width="100%"
                        text="Description"
                        placeholder="Enter Description"
                        onChange={handleChangeInput}
                        name="description"
                        multiline
                        rows={3}
                        value={data?.description}
                        valid
                    />
                    {data?.description ? "" : <ErrorLog text={errors?.description} />}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography fontSize={'16px'} lineHeight={1} fontWeight={500}> Product Details </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent
                        width="100%"
                        text="Length"
                        inCm={'(In CM)'}
                        placeholder="Enter Length"
                        onChange={handleChangeInput}
                        placeholderFontSize="13px"
                        name="length"
                        value={data?.length}
                        type="number"
                        valid
                    />
                    {data?.length ? "" : <ErrorLog text={errors?.length} />}
                    {data?.length > 0 ? "" : <ErrorLog text={errors?.lengthZero} />}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent
                        width="100%"
                        text="Breadth"
                        placeholder="Enter Breadth"
                        inCm={'(In CM)'}
                        onChange={handleChangeInput}
                        placeholderFontSize="13px"
                        name="breadth"
                        value={data?.breadth}
                        type="number"
                        valid
                    />
                    {data?.breadth ? "" : <ErrorLog text={errors?.breadth} />}
                    {data?.breadth > 0 ? "" : <ErrorLog text={errors?.breadthZero} />}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent
                        width="100%"
                        text="Height"
                        placeholder="Enter Height"
                        inCm={'(In CM)'}
                        onChange={handleChangeInput}
                        placeholderFontSize="13px"
                        name="height"
                        value={data?.height}
                        type="number"
                        valid
                    />
                    {data?.height ? "" : <ErrorLog text={errors?.height} />}
                    {data?.height > 0 ? "" : <ErrorLog text={errors?.heightZero} />}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent
                        width="100%"
                        text="Weight"
                        placeholder="Enter Weight"
                        inKg={'(In KG)'}
                        onChange={handleChangeInput}
                        placeholderFontSize="13px"
                        name="weight"
                        value={data?.weight}
                        type="number"
                        valid
                    />
                    {data?.weight ? "" : <ErrorLog text={errors?.weight} />}
                    {data?.weight > 0 ? "" : <ErrorLog text={errors?.weightZero} />}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <TextFieldComponent
                        width="100%"
                        text="Stock Quantity"
                        placeholder="Enter Stock Quantity"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 4) {
                                handleChangeInput(e);
                            }
                        }}
                        name="stockQuantity"
                        value={data?.stockQuantity}
                        type="number"
                        valid
                    />
                    {data?.stockQuantity ? "" : <ErrorLog text={errors?.stockQuantity} />}
                    {data?.stockQuantity > 0 ? "" : <ErrorLog text={errors?.stockQuantityZero} />}
                </Grid> */}
                <Grid item xs={12} sm={12}>
                    <FilePreviewModel
                        isOpen={isOpen}
                        onClick={() => setIsOpen(true)}
                        handleClose={() => setIsOpen(false)}
                        id={"image-upload"}
                        htmlFor={"image-upload"}
                        accept={".jpeg,.jpg,.png"}
                        name={"image"}
                        fileLink={data?.fileUrl}
                        title={"Image"}
                        btnText={"Upload"}
                        label="Upload Main Image"
                        fileName={data?.fileName}
                        isSingleUpload={true}
                        onChange={handleImageUpload}
                        deleteFile={deleteFile}
                        data={data}
                        valid
                    />
                    {data?.fileUrl ? "" : <ErrorLog text={errors?.fileUrl} />}
                    {data?.imageSize ? "" : <ErrorLog text={errors?.imageSize} />}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FilePreviewModel
                        isOpen={previewFileIndex}
                        onClick={handleOpenPreview}
                        handleClose={handleClosePreview}
                        id={"image-multiple-upload"}
                        htmlFor={"image-multiple-upload"}
                        accept={".jpeg,.jpg,.png"}
                        name={"image"}
                        multiplefilesData={data?.multiplefiles}
                        title={"Image"}
                        btnText={"Upload"}
                        label="Upload Multiple Sub Image"
                        onChange={handleMultipleImageUpload}
                        isMultipleUpload={true}
                        deleteFile={deleteFile}
                        setData={setData}
                        data={data}
                        valid
                    />
                    {data?.multiplefiles?.length > 0 ? "" : <ErrorLog text={errors?.multiplefiles} />}
                    {data?.multipleImageSize ? "" : <ErrorLog text={errors?.multipleImageSize} />}
                </Grid>
            </Grid>
        </>
    )
}

export default AddUpdateNote
