import { toast } from "react-toastify";
import swal from "sweetalert";

export default function ErrorHandler(data) {
  if (data?.payload?.status) {
    if (data?.payload?.status === 500 || data?.payload?.status === 502) {
      toast.error(data?.payload?.message);
      return false
    } else if (data?.payload?.status === 404) {
      toast.error(data?.payload?.message);
      return false
    } else if (data?.payload?.status === 400) {
      toast.error(data?.payload?.message);
      return false
    } else if (data?.payload?.status === 409) {
      toast.error(data?.payload?.message);
      return false
    } else if (data?.payload?.status === 403) {
      toast.error(data?.payload?.message);
      return false
    } else if (data?.payload?.status === 401) {
      toast.error(data?.payload?.message);
      localStorage.clear()
      window.location.href = '/'
      return false
    } else {
      return true
    }
  } else {
    swal("error", 'Something went wrong!', "error")
    return false
  }
}

export  function trimAndRemoveExtraSpaces(str) {
  return str?.trim().replace(/\s+/g, ' ') || '';
}