import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiGet, ApiPost } from "../../ApiSetUp/api";
import { api } from "../../ApiSetUp/Axios";

//get Question
export const getQuestion = createAsyncThunk("getQuestion", async (body) => {
    return ApiPost(api?.getQuestions, body)
        .then((response) => response?.data)
        .catch((error) => error)
});

//get Question By Id
export const getQuestionById = createAsyncThunk("getQuestionById", async (body) => {
    return ApiGet(api?.getQuestionsById + body)
        .then((response) => response?.data)
        .catch((error) => error)
});

//create Question
export const createQuestion = createAsyncThunk("createQuestion", async (body) => {
    return ApiPost(api?.createQuestion, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//update Question
export const updateQuestion = createAsyncThunk("updateQuestion", async (body) => {
    return ApiPost(api?.updateQuestion, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});