import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import sessionStoreUtil from "../../ApiSetUp/LocalStorage"


export const ProtectedRoutes = () => {

    const auth_token = sessionStoreUtil.getLSItem('authtoken')
    const navigate = useNavigate()
    const isAuth = auth_token ? true : false;

    useEffect(() => {
        if (!isAuth) {
            navigate("/login")
        }
    }, [isAuth, navigate])

    return isAuth ? <Outlet /> : null;
};
