import { styled } from "@mui/material/styles";
import { TableRow } from "@mui/material";
const StyledTableRow = styled(TableRow)(({ theme, isLastRow }) => ({
  backgroundColor: theme.palette.bgLightWhite.main,
  borderBottom: isLastRow ? "none" : "8px solid " + theme.palette.background.default,
  borderCollapse: "separate",
  borderSpacing: "0 5px",
  borderRadious: "10px !important",
  marginTop: "-10px",
  ".MuiTableRow-root": {
    borderRadius: "5px",
  },
}));
export default StyledTableRow;
