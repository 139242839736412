import { createTheme } from "@mui/material/styles";

// Create a theme instance.

export const defaultPrimaryColor = "#4c5739";
export const defaultInfoColor = "#a5b38c";
export const defaultSecondaryColor = "##F6D767";
export const defaultWarningColor = "#FBBF45";
export const secondaryWarningColor = "#fbbf4521";
export const secondaryInfoColor = "#68abf51a";
export const defaultDangerColor = "#F88D64";
export const secondaryDangerColor = "#f88d641a"

export const defaultbgGreenLight = "#a8dadd";
export const defaultBgLightWhite = "#FFFFFF";
export const defaultBgDarkGreen = "#3b8a51";
export const defaultBgLightGrey = "#ebeced";
export const defaultBgInfo = "#68ABF5";
export const secondaryBgInfo = "#3563db12"
export const defaultBgLightRed = "#F14336";
export const defaultBg = "#f5f5f4";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: defaultPrimaryColor
    },
    secondary:{
      main: defaultSecondaryColor
    },
    warning: {
      main: defaultWarningColor,
      secondary: secondaryWarningColor
    },
    info: {
      main: defaultInfoColor,
      secondary:secondaryBgInfo
    },
    error:{
      main: defaultDangerColor,
      secondary: secondaryDangerColor
    },
    bgLightGreen: {
      main: defaultbgGreenLight,
    },
    bgLightWhite: {
      main: defaultBgLightWhite,
    },
    bgDarkGreen: {
      main: defaultBgDarkGreen,
    },
    bgLightGrey: {
      main: defaultBgLightGrey
    },
    bgInfo: {
      main: defaultBgInfo,
      secondary:secondaryBgInfo
    },
    bgDanger: {
      main: defaultDangerColor,
      secondary: secondaryDangerColor
    },
    bgLightRed: {
      main: defaultBgLightRed
    },
    background: {
      default: defaultBg
    }
  },
  breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1536,
      }
  }
})