import React from "react";
import { Button, CircularProgress } from "@mui/material";

const ButtonContained = ({
    text,
    height,
    width,
    marginRight,
    marginLeft,
    borderRadius,
    endIcon,
    startIcon,
    onClick,
    marginTop,
    marginBottom,
    color,
    disabled,
    padding,
    type,
    loading,
    disableLoader,
    fontWeight,
    href,
    fontSize,
    size,
    sx
}) => {
    return (
        <Button
            type={type}
            variant="contained"
            color={color === "error" ? "error" : "primary"}
            sx={{
                ":hover": {
                    backgroundColor:
                        color === "error"
                            ? (theme) => theme?.palette?.error?.main
                            : (theme) => theme?.palette?.primary?.main,
                },
                borderRadius: borderRadius ? borderRadius : "20px",
                marginTop: marginTop,
                marginBottom: marginBottom,
                height: height || '35px',
                width: width,
                marginRight: marginRight,
                marginLeft: marginLeft,
                fontSize: fontSize,
                padding: padding || "7px 16px",
                fontWeight: fontWeight,
                textTransform: 'capitalize',
                ...sx
            }}
            size={size || "medium"}
            disableElevation
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={onClick}
            disabled={disabled}
            href={href}
        >
            {text}
            {loading && !disableLoader ? (
                <CircularProgress
                    size={20}
                    color={"inherit"}
                    sx={{ marginLeft: 3 }}
                />
            ) : (
                ""
            )}
        </Button>
    );
};

export default ButtonContained;
