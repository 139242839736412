import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, useTheme } from '@mui/material';

export default function CustomDialog({ isOpen, handleClose, title, action, maxWidth, children, width, padding, paid }) {
  const theme = useTheme()
  return (
    <Dialog
      onClose={(e, reason) => {
        if (reason === 'backdropClick') return;
        handleClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      PaperProps={{
        sx: { width: width, padding: padding || "2px" },
      }}
      maxWidth={maxWidth}
      disableEscapeKeyDown
    >
      <DialogTitle fontSize={'17px'} sx={{ m: 0, p: 0.5, color: theme?.palette?.primary?.main, }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      {paid &&
        <Box sx={(theme) => ({ position: 'absolute', padding: 0.5, right: 35, top: 0, color: theme.palette.grey[500], })}>
          {paid}
        </Box>
      }
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({ position: 'absolute', padding: 0.5, right: 8, color: theme.palette.grey[500], })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{padding:"10px 10px"}}>
        {children}
      </DialogContent>
      <DialogActions>
        {action}
      </DialogActions>
    </Dialog>
  );
}
