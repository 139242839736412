
import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
function Loader() {
    const loader = useSelector((state) => state.loader.value)

    return (
        <>
            {loader ? (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',  
                        zIndex: 9999,
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            ) : null}
        </>
    )
}

export default Loader;
