import React from 'react'
import TextEditor from '../Common/CommonTextEditor'
import ReactQuill from 'react-quill'
import 'quill/dist/quill.snow.css'
import { makeStyles } from 'tss-react/mui';
import { Box, InputLabel, Typography } from '@mui/material';

var modules = {
    toolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] }
        ],
        [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        [{ "background": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
};

var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size", "background"
];
const useStyles = makeStyles()((theme) => {
    return {
        customTextEditor: {
            "& .ql-editor": {
                height: "100px"
            },
            "& .ql-toolbar.ql-snow": {
                border: `1px solid #9cab82`,
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
            },
            "& .ql-container.ql-snow": {
                border: `1px solid #9cab82`,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
            },
        }
    }
});


const CommonTextEditor = (props) => {
    const {
        handleContentChange,
        value,
        text,
        valid,
        fontWeight,
        labelColor,
        whiteSpace,
        subText
    } = props;
    // ------------------ Hooks ------------------
    const { classes } = useStyles();

    return (
        <>
            {text && (
                <Box
                    mb={1}
                    display="flex"
                    fontSize="12px"
                    flexDirection="row"
                >
                    <InputLabel
                        sx={{
                            marginRight: "2px",
                            fontWeight: fontWeight,
                            fontSize: "14px",
                            color: labelColor,
                            whiteSpace: whiteSpace ? "wrap" : "nowrap",
                        }}
                    >
                        {text}
                    </InputLabel>
                    {subText && <Typography color={'Gray'} component="caption" fontSize={'11px'} variant="body2">
                        {subText}
                    </Typography>}
                    {valid && (
                        <Typography color="#EF627A" component="caption" variant="body2">
                            *
                        </Typography>
                    )}
                </Box>
            )}
            <ReactQuill
                theme="snow"
                modules={modules}
                value={value}
                formats={formats}
                placeholder="write your content ...."
                onChange={handleContentChange}
                className={classes?.customTextEditor}
            />
        </>
    )
}

export default CommonTextEditor
