import { Typography } from '@mui/material'

function ErrorLog({ text }) {
    return (
        <>
            {text && (
                <Typography
                    color={'red'}
                    fontSize={'11px'}
                    mt={0.2}
                >
                    {text}
                </Typography>
            )}
        </>
    )
}

export default ErrorLog;
