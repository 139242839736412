import { Grid, } from '@mui/material';
import TextFieldComponent from '../Common/TextFieldComponent';
import FilePreviewModel from '../Common/FilePreviewModel';
import { useState } from 'react';
import ErrorLog from '../Common/ErrorLog';

const AddUpdateSubject = ({ handleChangeInput, handleImageUpload, data, deleteFile, errors }) => {
    const [isOpen, setIsOpen] = useState(false)
    console.log(errors?.subjectName, "errors?.subjectName")
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <TextFieldComponent
                    width="100%"
                    text="Subject Name"
                    placeholder="Enter Subject Name"
                    onChange={handleChangeInput}
                    name="subjectName"
                    value={data?.subjectName}
                    valid
                />
                {data?.subjectName ? "" : <ErrorLog text={errors?.subjectName} />}
            </Grid>
            <Grid item xs={12} sm={12}>
                <FilePreviewModel
                    isOpen={isOpen}
                    onClick={() => setIsOpen(true)}
                    handleClose={() => setIsOpen(false)}
                    id={"image-upload"}
                    htmlFor={"image-upload"}
                    accept={".jpeg,.jpg,.png"}
                    name={"subjectImage"}
                    fileLink={data?.subjectFileUrl}
                    title={"Subject Image"}
                    btnText={"Upload Image"}
                    label="Upload Image"
                    fileName={data?.subjectFileName}
                    onChange={handleImageUpload}
                    deleteFile={deleteFile}
                    data={data}
                    valid
                />
                {data?.subjectFileUrl ? "" : <ErrorLog text={errors?.subjectFileUrl} />}
                {data?.subjectImageSize ? "" : <ErrorLog text={errors?.subjectImageSize} />}
            </Grid>
        </Grid>
    );
};

export default AddUpdateSubject;
