import { Box, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CommonHeader from '../../Components/Common/CommonHeader';
import PaperContainer from '../../Components/Common/StyledTableCell';
import StyledTableCell from '../../Components/Common/StyledTableCell';
import StyledTableRow from '../../Components/Common/StyledTableRow';
import WidgetLoader from '../../Components/Common/widgetLoader';
import ButtonContained from '../../Components/Common/ButtonContained';
import AddUpdateQuestion from '../../Components/Question/AddUpdateQuestion';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { createQuestion, getQuestion, getQuestionById, updateQuestion } from '../../Redux/Slices/QuestionSlice';
import ErrorHandler from '../../Utils/handler';
import { useDispatch, } from 'react-redux';
import { getSubjects } from '../../Redux/Slices/subjectSlice';
import { getTopic } from '../../Redux/Slices/topicSlice';
import { getLecture } from '../../Redux/Slices/LectureSlice';
import CustomTooltip from '../../Components/Common/CustomTooltip';
import { useTheme } from '@emotion/react';
import DataNotFound from '../../Components/Common/dataNotFound';
import CustomPagination from '../../Components/Common/CustomPagination';
import { deleteFile, multipleUploadFile, uploadFile } from '../../Redux/Actions/action';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import { toast } from 'react-toastify';
import AutoCompleteSearch from '../../Components/Common/AutoCompleteSearch';
import ReadMore from '../../Components/Common/ReadMore';

function Questions() {

    //Hooks
    const theme = useTheme();
    const dispatch = useDispatch()

    //State
    const [isEdit, setisEdit] = React.useState(false);
    const [errors, setErrors] = React.useState({})
    const [questionDataList, setQuestionDataList] = React.useState({})
    const [data, setData] = React.useState({
        id: '',
        subjectName: '',
        topicName: '',
        lectureName: '',
        questionText: '',
        questionImage: "",
        questionVideoLink: "",
        options: [{ answerText: "", isCorrect: false }, { answerText: "", isCorrect: false }, { answerText: "", isCorrect: false }, { answerText: "", isCorrect: false }],
        description: "<p><br></p>",
        videoLink: '',
        'multiplefiles': [],
        correctOption: ''
    });
    const [loading, setLoading] = React.useState(false)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [isQuestionListShow, setIsQuestionListShow] = React.useState(false);
    const [oldQuestionFileUrl, setOldQuestionFileUrl] = React.useState("");
    const [subjects, setSubjects] = React.useState([])
    const [topics, setTopics] = React.useState([])
    const [lectures, setLectures] = React.useState([])

    const subjectOptions = subjects?.length > 0 ? subjects?.map((subject) => subject?.subjectName) : [];
    const topicOptions = topics?.filter((ele) => ele?.subjectDetails?.subjectName === data?.subjectName)?.map((value) => value?.topicName);
    const lectureOptions = lectures?.filter((lecture) => lecture?.topicDetails?.topicName === data?.topicName)?.map((value) => value?.lectureName);

    //  Validations 
    const validation = () => {
        let isValid = true;
        let error = {};

        if (!data?.subjectName) {
            isValid = false;
            error.subjectName = '*Subject name is required';
        }
        if (!data?.topicName) {
            isValid = false;
            error.topicName = '*Topic name is required';
        }
        if (!data?.lectureName) {
            isValid = false;
            error.lectureName = '*Lecture name is required';
        }
        if (!data?.questionText) {
            isValid = false;
            error.questionText = '*Question text is required';
        }

        let anySelected = data?.options?.some(option => option?.isCorrect);
        if (!anySelected) {
            isValid = false;
            error.isCorrect = "*Please select at least 1 answer";
        }

        let allOptionsFilled = data?.options?.every(option => option?.answerText);
        if (!allOptionsFilled) {
            isValid = false;
            error.answerText = "*Please enter all options.";
        }

        if (data?.description === "<p><br></p>") {
            isValid = false;
            error.description = '*Description is required';
        }

        if (data?.questionVideoLink) {
            let value = data?.questionVideoLink;
            // const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})(\S+)?$/;
            const googleDriveRegex = /(?:drive\.google\.com\/file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)/;

            // Validate the URL
            // const isYoutubeValid = youtubeRegex.test(value);
            const isGoogleDriveValid = googleDriveRegex.test(value);
            if (!isGoogleDriveValid) {
                isValid = false;
                error.questionVideoLink = '*Please enter valid google drive url';
            } else {
                error.questionVideoLink = '';
            }
        }

        if (data?.videoLink) {
            let value = data?.videoLink;
            // const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})(\S+)?$/;
            const googleDriveRegex = /(?:drive\.google\.com\/file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)/;

            // Validate the URL
            // const isYoutubeValid = youtubeRegex.test(value);
            const isGoogleDriveValid = googleDriveRegex.test(value);
            if (!isGoogleDriveValid) {
                isValid = false;
                error.videoLink = '*Please enter valid google drive url';
            } else {
                error.videoLink = '';
            }
        }

        // if (!data?.videoLink) {
        //     isValid = false;
        //     error.videoLink = 'Video is required';
        // }
        // if (!data?.multiplefiles?.length) {
        //     isValid = false;
        //     error.multiplefiles = 'Please upload at least one file';
        // }

        setErrors(error);
        return isValid;
    }
    console.log(data, "data")
    //handler
    const handleClear = () => {
        setisEdit(false);
        setData({
            ...data,
            id: '',
            questionText: '',
            questionImage: "",
            questionImageFileName: "",
            questionVideoLink: "",
            options: [{ answerText: "", isCorrect: false }, { answerText: "", isCorrect: false }, { answerText: "", isCorrect: false }, { answerText: "", isCorrect: false }],
            description: "<p><br></p>",
            videoLink: '',
            'multiplefiles': [],
            correctOption: '',
            multiplefileList: [],
            fileData: "",
        });
        setErrors({});
        setIsQuestionListShow(false)
        setOldQuestionFileUrl('')
    }

    const handleAutoCompleteChange = (name, newValue) => {
        if (name === "subjectName") {
            setData(prevState => ({
                ...prevState,
                subjectName: newValue,
                topicName: "",
                lectureName: "",
            }));
        } else if (name === "topicName") {
            setData(prevState => ({
                ...prevState,
                topicName: newValue,
                lectureName: "",
            }))
        }
        else {
            setData(prevState => ({
                ...prevState,
                [name]: newValue
            }));
        }
    };

    const handleChangeOptions = (e, index, isOption) => {
        const { value } = e.target;
        if (isOption) {
            const updatedOptions = data?.options?.map((opt, i) =>
                i === +value ? { ...opt, isCorrect: true } : { ...opt, isCorrect: false });
            setData((prevState) => ({
                ...prevState,
                options: updatedOptions,
                correctOption: value,
            }));
        } else {
            const updatedOptions = data?.options?.map((opt, i) =>
                i === index ? { ...opt, answerText: value } : opt);
            setData((prevState) => ({
                ...prevState,
                options: updatedOptions
            }));
        }
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageUpload = (acceptFile, type) => {
        const fileData = acceptFile?.target?.files?.[0]

        if (fileData) {
            // Check file type
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validTypes.includes(fileData.type)) {
                setErrors(errors => ({
                    ...errors,
                    questionImageSize: 'Please upload an image file (JPEG, PNG, JPG).',
                    questionImage: '',
                    questionImageFileName: "",
                }));
                return;
            }

            if (fileData?.size < 10485760) {
                // _uploadFile(fileData, false);
                setData(prevData => ({
                    ...prevData,
                    fileData: fileData,
                    questionImageFileName: fileData?.name,
                    questionImage: URL.createObjectURL(fileData)
                }))
                setErrors(errors => ({
                    ...errors,
                    questionImageSize: '',
                }));
            }
            else {
                setErrors(errors => ({
                    ...errors,
                    questionImageSize: 'File size should be less than 10mb.',
                    questionImage: '',
                    questionImageFileName: "",
                }));
                setData(prevData => ({
                    ...prevData,
                    fileData: null,
                    questionImageFileName: "",
                    questionImage: '',
                }))
            }
        }
    };

    const handelDeleteFile = (fileUrl, isMultiple, fileId) => {
        if (isMultiple) {
            setData(prevState => ({
                ...prevState,
                multiplefiles: prevState.multiplefiles?.filter((file) => file?.id !== fileId),
                multiplefileList: prevState.multiplefileList?.filter((fileData) => fileData?.id !== fileId)
            }));
        } else {
            setData(prevState => ({
                ...prevState,
                questionImage: "",
                questionImageFileName: "",
                fileData: "",
            }));
        }
    }

    const handleContentChange = (content) => {
        setData(prevState => ({
            ...prevState,
            description: content
        }));
    };

    const handleMultipleImageUpload = (acceptFile) => {
        const fileData = acceptFile?.target?.files
        console.log(acceptFile, typeof fileData, 'acceptFile', Object.values(fileData))
        const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const areAllFilesValidType = Array.from(fileData).every(file => validTypes.includes(file.type));
        const areAllFilesLessThan10MB = Array.from(fileData).every(file => file.size < 10485760);
        if (Object.values(fileData)?.length < 6) {
            if (areAllFilesValidType) {
                if (areAllFilesLessThan10MB) {
                    // _uploadFile(fileData, true);
                    const filesWithId = Object.values(fileData).map((fileItem, index) => ({
                        id: index + 1,
                        file: fileItem
                    }));

                    const filesWithUrlAndName = filesWithId.map((fileItem) => ({
                        id: fileItem.id,
                        fileUrl: URL.createObjectURL(fileItem.file),
                        fileName: fileItem.file?.name
                    }));

                    setData(prevData => ({
                        ...prevData,
                        multiplefileList: filesWithId,
                        multiplefiles: filesWithUrlAndName
                    }));
                    setErrors(errors => ({
                        ...errors,
                        imageSize: '',
                    }));
                }
                else {
                    setErrors(errors => ({
                        ...errors,
                        imageSize: 'File size should be less than 10mb.',
                        Image: ''
                    }));
                    setData(prevData => ({
                        ...prevData,
                        multiplefileList: [],
                        multiplefiles: []
                    }));
                }
            } else {
                setErrors(errors => ({
                    ...errors,
                    imageSize: 'Please upload image files (JPEG, PNG, JPG).',
                    Image: ''
                }));
            }
        } else {
            setErrors(errors => ({
                ...errors,
                imageSize: 'You can upload max 5 image!',
                Image: ''
            }));
        }

    };

    console.log(data, 'data')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const getSubjectId = subjects?.filter((subject) => subject?.subjectName === data?.subjectName)
    const getTopicId = topics?.filter((topic) => topic?.topicName === data?.topicName)
    const getLectureId = lectures?.filter((lecture) => lecture?.lectureName === data?.lectureName)

    //API Calling
    const createUpdateQuestion = async () => {
        if (validation()) {
            dispatch(showLoader(true))
            try {
                let body1 = {};
                let body = {};
                if (data?.fileData) {
                    const formData = new FormData();
                    formData.append('image', data?.fileData);

                    const result = await dispatch(uploadFile(formData)).unwrap();
                    if (result.success) {
                        setData(prevState => ({
                            ...prevState,
                            questionImage: result.data?.data?.image,
                            questionImageFileName: result.data?.data?.image?.split("image/")?.[1]
                        }));
                        body1.questionImage = result.data?.data?.image;
                        setErrors(errors => ({
                            ...errors,
                            questionImage: '',
                            questionImageSize: ''
                        }));
                    } else {
                        console.error('File upload error:', result.error);
                        return;
                    }
                }

                if (data?.multiplefileList?.length > 0) {
                    const formData = new FormData();
                    data?.multiplefileList?.forEach(fileItem => {
                        formData.append('image', fileItem.file);
                    });

                    const result = await dispatch(multipleUploadFile(formData)).unwrap();
                    if (result.success) {
                        const newFilesData = result.data?.data?.map((item, index) => ({
                            id: index + 1,
                            fileUrl: item?.image || '',
                            fileName: item?.image?.split("image/")?.[1] || '',
                        }));
                        body1.imageList = newFilesData;
                        setData(prevState => ({
                            ...prevState,
                            multiplefiles: newFilesData
                        }));
                        setErrors(errors => ({
                            ...errors,
                            imageSize: '',
                        }));
                    } else {
                        console.error('File upload error:', result.error);
                        return;
                    }
                }

                if (oldQuestionFileUrl && oldQuestionFileUrl !== data?.questionImage) {
                    _deleteFile(oldQuestionFileUrl);
                }

                body = {
                    "subjectId": getSubjectId?.[0]?._id,
                    "topicId": getTopicId?.[0]?._id,
                    "lectureId": getLectureId?.[0]?._id,
                    "questionText": data?.questionText,
                    "options": data?.options,
                    'questionImage': data?.questionImage || null,
                    'questionVideoLink': data?.questionVideoLink || null,
                    "correctAnswerDetails": {
                        "description": data?.description,
                        "videoLink": data?.videoLink,
                        "image": body1.imageList ? body1.imageList : data?.multiplefiles
                    }
                };

                if (data?.questionVideoLink) {
                    body.questionVideoLink = data?.questionVideoLink;
                }
                if (data?.questionImage) {
                    body.questionImage = body1.questionImage ? body1.questionImage : data?.questionImage;
                }
                if (isEdit && data?.id) {
                    body._id = data?.id;
                }

                // Log the body to check its contents

                const fn = isEdit ? updateQuestion : createQuestion;
                const result = await dispatch(fn(body))

                const error = ErrorHandler(result);
                if (error) {
                    toast.success(result?.payload?.message);
                    handleClear();
                } else {
                    console.error('Update error:', error);
                }
            } catch (error) {
                toast.error(error.message || 'An error occurred');
                console.log('Error:', error);
            } finally {
                dispatch(showLoader(false))
            }
        }
    };


    const _getQuestions = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: searchText || "",
            "subjectId": getSubjectId?.[0]?._id,
            "topicId": getTopicId?.[0]?._id,
            "lectureId": getLectureId?.[0]?._id,
        };
        try {
            const result = await dispatch(getQuestion(body));
            const error = ErrorHandler(result)
            if (error) {
                setQuestionDataList(result?.payload?.data)
            }
        }
        catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const _getSubjects = async () => {
        try {
            const result = await dispatch(getSubjects())
            const error = ErrorHandler(result)
            if (error) {
                const config = result?.payload?.data?.response
                if (config?.length > 0) {
                    setSubjects(config)
                    setData({
                        ...data,
                        subjectName: config?.[0]?.subjectName || '',
                    });
                }

            }
        } catch (error) {
            console.log(error)
        }
    }

    const _getTopic = async () => {
        try {
            const result = await dispatch(getTopic())
            const error = ErrorHandler(result)
            if (error) {
                const config = result?.payload?.data?.response
                if (config?.length > 0) {
                    setTopics(config)
                    const filteredTopics = config?.filter((ele) => ele?.subjectDetails?.subjectName === data?.subjectName);
                    if (filteredTopics?.length > 0) {
                        const topicName = filteredTopics?.[0]?.topicName || '';
                        console.log(topicName, "topicName")
                        setData({
                            ...data,
                            topicName: topicName || '',
                        });
                    }
                    else {
                        setQuestionDataList([]);
                    }
                }

            }
        } catch (error) {
            console.log(error)
        }
    }
    console.log(data, "data")
    const _getLectures = async () => {
        try {
            const result = await dispatch(getLecture())
            const error = ErrorHandler(result)
            if (error) {
                const config = result?.payload?.data?.response
                if (config?.length > 0) {
                    setLectures(config)
                    const filteredTopics = config?.filter((ele) => ele?.topicDetails?.topicName === data?.topicName);
                    if (filteredTopics?.length > 0) {
                        const lectureName = filteredTopics?.[0]?.lectureName || '';
                        setData({
                            ...data,
                            lectureName: lectureName || '',
                        });
                    } else {
                        setQuestionDataList([]);
                    }
                }

            }
        } catch (error) {
            console.log(error)
        }
    }

    const _getQuestionsById = async (id) => {
        dispatch(showLoader(true));
        try {
            const body = `/${id}`
            const result = await dispatch(getQuestionById(body))
            const error = ErrorHandler(result)
            if (error) {
                const Question = result?.payload?.data
                setData({
                    id: Question?._id,
                    subjectName: Question?.subjectDetails?.subjectName,
                    topicName: Question?.topicDetails?.topicName,
                    lectureName: Question?.lectureDetails?.lectureName,
                    questionText: Question?.questionText,
                    questionVideoLink: Question?.questionVideoLink,
                    options: Question?.options,
                    description: Question?.correctAnswerDetails?.description,
                    videoLink: Question?.correctAnswerDetails?.videoLink,
                    multiplefiles: Question?.correctAnswerDetails?.image,
                    questionImage: Question?.questionImage,
                    questionImageFileName: Question?.questionImage?.split("image/")?.[1],
                    correctOption: Question?.options?.findIndex(element => element?.isCorrect),
                })
                setOldQuestionFileUrl(Question?.questionImage)
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false));
        }
    }

    const _deleteFile = async (fileUrl, isMultipleUpload) => {
        try {
            const result = await dispatch(deleteFile({ "url": fileUrl, }))
            const error = ErrorHandler(result)
            if (error) {
                if (isMultipleUpload) {
                    setData(prevState => ({
                        ...prevState,
                        multiplefiles: prevState.multiplefiles?.filter((file) => file?.fileUrl !== fileUrl),
                    }));
                } else {
                    setData(prevState => ({ ...prevState, questionImageFileName: "", questionImage: "", fileData: "" }));
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    //Effect
    useEffect(() => {
        _getSubjects()
    }, [])

    useEffect(() => {
        if (data?.subjectName) _getTopic()
    }, [data?.subjectName])

    useEffect(() => {
        if (data?.topicName) _getLectures()
    }, [data?.topicName])

    useEffect(() => {
        if (data?.subjectName && data?.topicName && data?.lectureName && !isQuestionListShow) _getQuestions()
    }, [isQuestionListShow, data?.subjectName, data?.topicName, data?.lectureName, rowsPerPage, page, searchText])

    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                {!isQuestionListShow && <><Grid item xs={12}>
                    <CommonHeader
                        title="Question List"
                        onClick={() => setIsQuestionListShow(true)}
                        btnText="Add Question"
                        disableButton={(getSubjectId?.[0]?._id && getLectureId?.[0]?._id && getTopicId?.[0]?._id) ? false : true}
                        handleSearch={(e) => { setPage(1); setSearchText(e); }}
                        otherComponent={
                            <>
                                <Box width={'250px'}>
                                    <AutoCompleteSearch
                                        width="100%"
                                        textLabel="Subject"
                                        placeholder="Search Subject"
                                        name="subjectName"
                                        handleChange={(newValue) => handleAutoCompleteChange("subjectName", newValue)}
                                        options={subjectOptions}
                                        value={data?.subjectName}
                                        isSearchIcon={false}
                                    />
                                </Box>
                                <Box width={'250px'}>
                                    <AutoCompleteSearch
                                        width="100%"
                                        textLabel="Topic"
                                        placeholder="Search Topic"
                                        name="topicName"
                                        handleChange={(newValue) => handleAutoCompleteChange("topicName", newValue)}
                                        options={topicOptions}
                                        value={data?.topicName}
                                        isSearchIcon={false}
                                    />
                                </Box>

                                <Box width={'250px'}>
                                    <AutoCompleteSearch
                                        width="100%"
                                        textLabel="Lecture"
                                        placeholder="Search Lecture"
                                        name="lectureName"
                                        handleChange={(newValue) => handleAutoCompleteChange("lectureName", newValue)}
                                        options={lectureOptions}
                                        value={data?.lectureName}
                                        isSearchIcon={false}
                                    />
                                </Box>
                            </>
                        }
                    />
                </Grid>
                    <Grid item xs={12}>
                        <TableContainer sx={{ width: "100%" }} >
                            {!loading ? (
                                <Table sx={{ minWidth: 700 }}>
                                    <TableHead component={PaperContainer} >
                                        <StyledTableRow>
                                            <StyledTableCell align='left'>No.</StyledTableCell>
                                            <StyledTableCell align='left'>Question Text</StyledTableCell>
                                            <StyledTableCell align="left">Created At</StyledTableCell>
                                            <StyledTableCell align="left">Action</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {questionDataList?.response?.length > 0 ? questionDataList?.response?.map((question, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell component="th" scope="row">{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                    <StyledTableCell component="th" scope="row"><ReadMore content={question?.questionText} maxLength={100} /></StyledTableCell>
                                                    <StyledTableCell component="th" scope="row"> {question?.createdAt ? new Date(question?.createdAt).toLocaleDateString() : 'N/A'}</StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                        <CustomTooltip title={'Edit'}>
                                                            <IconButton size='small' aria-label="edit" sx={{ backgroundColor: theme.palette.bgInfo.secondary, color: theme.palette.bgInfo.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                                                setIsQuestionListShow(true)
                                                                setisEdit(true);
                                                                _getQuestionsById(question?._id)
                                                            }}>
                                                                <EditOutlinedIcon fontSize='small' />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        }) :
                                            <StyledTableRow>
                                                <TableCell colSpan={12}>
                                                    <DataNotFound title="No Question Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                                </TableCell>
                                            </StyledTableRow>
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <WidgetLoader />
                            )}
                        </TableContainer>
                    </Grid>
                    {questionDataList?.count > 0 &&
                        <Grid item xs={12}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(questionDataList?.count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />

                        </Grid>
                    }</>}
                {isQuestionListShow &&
                    <Box bgcolor={theme.palette.bgLightWhite.main} sx={{ borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }} >
                        <Box p={1} sx={{
                            backgroundColor: theme.palette.info.main,
                            borderTopLeftRadius: '7px',
                            borderTopRightRadius: '7px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                        }}>
                            <Typography color={theme.palette.bgLightWhite.main} fontSize={'18px'} fontWeight={700} >{isEdit ? 'Edit Question' : 'Add Question'}</Typography>
                            <Box>
                                <CustomTooltip title={'Back to Question list'}>
                                    <IconButton size='small' aria-label="edit" sx={{
                                        backgroundColor: theme.palette.bgLightWhite.main, color: theme.palette.info.dark,
                                        '&:hover': {
                                            backgroundColor: theme.palette.bgLightWhite.main,
                                            color: theme.palette.info.dark,
                                        },
                                    }} onClick={() => {
                                        setIsQuestionListShow(false)
                                        handleClear()
                                    }}>
                                        <ArrowBackIcon fontSize='small' />
                                    </IconButton>
                                </CustomTooltip>
                            </Box>
                        </Box>
                        <Box p={2}>
                            <AddUpdateQuestion
                                handleClose={handleClear}
                                isEdit={isEdit}
                                handleChangeInput={(e) => handleChangeInput(e)}
                                data={data}
                                setData={setData}
                                handleChangeOptions={handleChangeOptions}
                                handleImageUpload={handleImageUpload}
                                handleMultipleImageUpload={handleMultipleImageUpload}
                                handleContentChange={handleContentChange}
                                deleteFile={handelDeleteFile}
                                errors={errors}
                            />
                            <Stack direction="row" justifyContent="flex-end" mr={2} >
                                <ButtonContained height={'35px'} padding={'6px 10px'} text={isEdit ? 'Update Question' : 'Save Question'} onClick={() => {
                                    createUpdateQuestion()
                                }} />
                            </Stack>
                        </Box>
                    </Box>}
            </Grid>
        </>
    )
}

export default Questions
