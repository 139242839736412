import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiGet, ApiPost, ApiPostNoAuth } from '../../ApiSetUp/api'
import { api } from '../../ApiSetUp/Axios';

//Login
export const adminLogin = createAsyncThunk('adminLogin', async (body) => {
    return ApiPostNoAuth(api.adminLogIn, body)
        .then((response) => response?.data)
        .catch((error) => error);
})
// Dashboard
export const getDashboardDetails = createAsyncThunk('getDashboardDetails', async (body) => {
    return ApiGet(api?.dashboardDetails)
        .then((response) => response?.data)
        .catch((error) => error);
})

//get Users
export const getUsers = createAsyncThunk('getUsers', async (body) => {
    console.log(body, 'bodyuser')
    return ApiPost(api.getUsers, body)
        .then((response) => {
            return response;
        })
        .catch((error) => { return (error, console.log(error, 'errorr...')) });
})

// active/Inactive users
export const activeInactiveUser = createAsyncThunk('activeInactiveUser', async (body) => {
    return ApiPost(api.activeInactiveUser, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
})


// Upload File
// export const uploadFile = createAsyncThunk("uploadFile", async (file,dispatch) => {
//     return ApiPost(api?.uploadFile, file)
//         .then((response) => {

//             return response?.data
//         })
//         .catch((error) => error)
// });

export const uploadFile = createAsyncThunk("uploadFile", async (file, { rejectWithValue }) => {
    try {
        const response = await ApiPost(api?.uploadFile, file);
        if (response.status === 200) {
            return { success: true, data: response?.data };
        } else {
            return rejectWithValue({ success: false, error: 'Upload failed' });
        }
    } catch (error) {
        return rejectWithValue({
            success: false,
            error: error?.response?.data || error.message || 'Something went wrong',
        });
    }
}
);

export const multipleUploadFile = createAsyncThunk("multipleUploadFile", async (file, { rejectWithValue }) => {
    try {
        const response = await ApiPost(api?.multipleUploadFile, file);
        if (response.status === 200) {
            return { success: true, data: response?.data };
        } else {
            return rejectWithValue({ success: false, error: 'Upload failed' });
        }
    } catch (error) {
        return rejectWithValue({
            success: false,
            error: error?.response?.data || error.message || 'Something went wrong',
        });
    }
}
);

// Delete File
export const deleteFile = createAsyncThunk("deleteFile", async (body) => {
    return ApiPost(api?.deleteFile, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//get Notes
export const getNotes = createAsyncThunk('getNotes', async (body) => {
    return ApiPost(api?.getNotes, body)
        .then((response) => response?.data)
        .catch((error) => error);
})

//get notes by Id 
export const getNotesById = createAsyncThunk('getNotesById', async (body) => {
    return ApiGet(api?.getNotesById + body)
        .then((response) => response?.data)
        .catch((error) => error);
})

//create Notes
export const createNotes = createAsyncThunk('createNotes', async (body) => {
    return ApiPost(api?.createNote, body)
        .then((response) => response?.data)
        .catch((error) => error);
})

// update Notes
export const updateNotes = createAsyncThunk('updateNotes', async (body) => {
    return ApiPost(api?.updateNote, body)
        .then((response) => response?.data)
        .catch((error) => error);
})

//activeInactive Subject
export const activeInactiveNote = createAsyncThunk("activeInactiveNote", async (body) => {
    return ApiPost(api?.activeInactiveNote, body)
        .then((response) => {
            return response?.data
        })
        .catch((error) => error)
});

//getUser Queries
export const getUserQueries = createAsyncThunk('getUserQueries', async (body) => {
    return ApiPost(api?.getUserQueries, body)
        .then((response) => response?.data)
        .catch((error) => error);
})