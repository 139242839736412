import { Box, FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import TextFieldComponent from '../Common/TextFieldComponent'
import ErrorLog from '../Common/ErrorLog'
import FilePreviewModel from '../Common/FilePreviewModel'
import CommonTextEditor from '../Common/CommonTextEditor'

function AddUpdateQuestion({ data, setData, handleChangeInput, handleContentChange, handleMultipleImageUpload, handleImageUpload, deleteFile, handleChangeOptions, errors }) {
    const theme = useTheme()
    const [isOpen, setIsOpen] = useState(false)
    const [previewFileIndex, setPreviewFileIndex] = useState(null);

    const handleOpenPreview = (index) => {
        setPreviewFileIndex(index);
    }

    const handleClosePreview = (index) => {
        setPreviewFileIndex(null);
    }

    return (
        <>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={6}>
                    <TextFieldComponent
                        width="100%"
                        text="Question Text"
                        placeholder="Enter Question Text"
                        onChange={handleChangeInput}
                        name="questionText"
                        value={data?.questionText}
                        valid
                    />
                    {data?.questionText ? "" : <ErrorLog text={errors?.questionText} />}
                </Grid>
                <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Box display={'flex'} gap={1.5} alignItems={'center'} flexWrap={'wrap'}>
                            <FilePreviewModel
                                isOpen={isOpen}
                                onClick={() => setIsOpen(true)}
                                handleClose={() => setIsOpen(false)}
                                id={"image-upload"}
                                htmlFor={"image-upload"}
                                accept={".jpeg,.jpg,.png"}
                                name="questionImage"
                                fileLink={data?.questionImage}
                                disabled={!!data?.questionVideoLink}
                                title="Image"
                                btnText={"Upload Image"}
                                label="Upload Image For Question"
                                fileName={data?.questionImageFileName}
                                onChange={(e) => handleImageUpload(e, 'single')}
                                deleteFile={deleteFile}
                                data={data}
                            />
                            <Typography sx={{ fontSize: "16px", bgcolor: theme.palette.info.main, padding: "5px 8px", borderRadius: "50%", color: theme.palette.bgLightWhite.main }}> Or </Typography>
                        </Box>
                        {data?.questionImageSize ? "" : <ErrorLog text={errors?.questionImageSize} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextFieldComponent
                            width="100%"
                            text="Video URL For Question"
                            size="small"
                            name='questionVideoLink'
                            placeholder="Enter Question Video URL"
                            value={data?.questionVideoLink}
                            disabled={!!data?.questionImage}
                            onChange={handleChangeInput}
                            onPaste={(e) => {
                                e.preventDefault();
                                const value = e?.clipboardData?.getData("text");
                                let videoId = "";
                                let finalEmbedLink = "";

                                // YouTube link handling
                                const youtubeMatch = /watch\?v=(.+)/.exec(value) || /youtu\.be\/(.+)/.exec(value) || /embed\/(.+?)"/.exec(value);
                                if (youtubeMatch) {
                                    videoId = youtubeMatch[1];
                                    finalEmbedLink = `https://www.youtube.com/embed/${videoId}`;
                                }

                                // Google Drive link handling
                                const googleDriveMatch = /(?:drive\.google\.com\/file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)/.exec(value);
                                if (googleDriveMatch) {
                                    const driveId = googleDriveMatch[1];
                                    finalEmbedLink = `https://drive.google.com/file/d/${driveId}/preview`;
                                }

                                // Update the videoLink field
                                if (finalEmbedLink) {
                                    setData(prevState => ({
                                        ...prevState,
                                        questionVideoLink: finalEmbedLink,
                                        questionImage: null
                                    }));
                                } else {
                                    console.error("Invalid URL:", value);
                                }
                            }}
                            helperText="Paste a Google Drive URL to automatically convert it to an embed URL."
                        />
                        {!data?.questionVideoLink ? "" : <ErrorLog text={errors?.questionVideoLink} />}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel sx={{ marginRight: "2px", fontSize: "16px", }}>
                            Question Options
                        </InputLabel>
                        <FormControl sx={{ width: "100%", mt: 2 }}>
                            <RadioGroup
                                value={data?.correctOption}
                            >
                                {data?.options?.map((option, index) => (
                                    <>
                                        <Box display={'flex'} key={index}>
                                            <FormControlLabel
                                                sx={{ marginBottom: "15px" }}
                                                value={index}
                                                control={<Radio size="small" color="primary" name={'answerOption'} onChange={(e) => handleChangeOptions(e, index, true)} />}
                                            />
                                            <TextFieldComponent
                                                width='100%'
                                                size={"small"}
                                                placeholder={`Enter option ${index + 1} here`}
                                                label={`Option ${index + 1}`}
                                                name={`option${index}`}
                                                value={option?.answerText}
                                                required
                                                type={"text"}
                                                onChange={(e) => handleChangeOptions(e, index, false)}
                                                valid
                                            />
                                        </Box>
                                    </>
                                ))}
                            </RadioGroup>
                            {data?.options?.some(option => option?.isCorrect) ? "" : <ErrorLog text={errors.isCorrect} />}
                            {data?.options?.every(option => option?.answerText) ? "" : <ErrorLog text={errors.answerText} />}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <Typography fontSize={'16px'} fontWeight={500}> Correct Answer Details </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CommonTextEditor
                            text="Description"
                            handleContentChange={handleContentChange}
                            value={data?.description}
                            valid
                        />
                        {(data?.description !== "<p><br></p>") ? "" : <ErrorLog text={errors?.description} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FilePreviewModel
                            isOpen={previewFileIndex}
                            onClick={handleOpenPreview}
                            handleClose={handleClosePreview}
                            id={"image-multiple-upload"}
                            htmlFor={"image-multiple-upload"}
                            accept={".jpeg,.jpg,.png"}
                            name={"image"}
                            multiplefilesData={data?.multiplefiles}
                            title={"Image"}
                            btnText={"Upload"}
                            label="Upload Multiple Image"
                            onChange={handleMultipleImageUpload}
                            isMultipleUpload={true}
                            deleteFile={deleteFile}
                            setData={setData}
                            data={data}
                            subText='(Optional)'
                        />
                        {data?.imageSize ? "" : <ErrorLog text={errors?.imageSize} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextFieldComponent
                            width="100%"
                            text="Video URL"
                            size="small"
                            name='videoLink'
                            placeholder="Enter Lecture Video URL"
                            value={data?.videoLink}
                            onChange={handleChangeInput}
                            onPaste={(e) => {
                                e.preventDefault();
                                const value = e?.clipboardData?.getData("text");
                                let videoId = "";
                                let finalEmbedLink = "";

                                // YouTube link handling
                                const youtubeMatch = /watch\?v=(.+)/.exec(value) || /youtu\.be\/(.+)/.exec(value) || /embed\/(.+?)"/.exec(value);
                                if (youtubeMatch) {
                                    videoId = youtubeMatch[1];
                                    finalEmbedLink = `https://www.youtube.com/embed/${videoId}`;
                                }

                                // Google Drive link handling
                                const googleDriveMatch = /(?:drive\.google\.com\/file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)/.exec(value);
                                if (googleDriveMatch) {
                                    const driveId = googleDriveMatch[1];
                                    finalEmbedLink = `https://drive.google.com/file/d/${driveId}/preview`;
                                }

                                // Update the videoLink field
                                if (finalEmbedLink) {
                                    setData(prevState => ({
                                        ...prevState,
                                        videoLink: finalEmbedLink
                                    }));
                                } else {
                                    console.error("Invalid URL:", value);
                                }
                            }}
                            helperText="Paste a YouTube Or Google Drive URL to automatically convert it to an embed URL."
                            subText='(Optional)'
                        />
                        {!data?.videoLink ? "" : <ErrorLog text={errors?.videoLink} />}
                    </Grid>
                    {data?.videoLink && <Grid item xs={12} sm={12}>
                        <iframe src={data?.videoLink}
                            width={'100%'}
                            height="380"
                            allow="autoplay"
                            title='videoLink'
                        >
                        </iframe>

                    </Grid>}
                </Grid>
            </Grid>
        </>
    )
}

export default AddUpdateQuestion
