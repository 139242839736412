import { InputLabel, Typography, Box, InputAdornment, IconButton, TextField, } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import emojiRegex from "emoji-regex";

const useStyles = makeStyles()((theme) => {
  return {
    main: {
      background: theme?.palette?.bgWhite?.main,
      minWidth: "50px",
      '& .MuiFormHelperText-root': {
        fontSize: "10px",
        marginLeft: '0px',
      }
    },
    error: {
      border: "1px solid green",
    },
  };
});

const TextFieldComponent = (props) => {
  const {
    text,
    type,
    label,
    placeholder,
    height,
    width,
    fullWidth,
    valid,
    multiline,
    rows,
    name,
    value,
    inCm,
    inKg,
    onChange,
    onInput,
    inputProps,
    placeholderFontSize,
    defaultValue,
    fontWeight,
    labelColor,
    showPasswordToggle,
    className,
    bgcolor,
    onKeyDown,
    onPaste,
    onBlur,
    disabled,
    whiteSpace,
    helperText,
    onDrag,
    subText
  } = props;

  const { classes } = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const regex = emojiRegex();

  return (
    <>
      {text && (
        <Box mb={0.5} display="flex" fontSize="12px" flexDirection="row" alignItems={'center'} gap={0.5}>
          <InputLabel sx={{ marginRight: "2px", fontWeight: fontWeight, fontSize: "14px", color: labelColor, whiteSpace: whiteSpace ? "wrap" : "nowrap", }}>
            {text}
          </InputLabel>
          {subText && <Typography color={'Gray'} component="caption" fontSize={'10px'} variant="body2">{subText}</Typography>}
          {inCm && <Typography color={'Gray'} component="caption" fontSize={'10px'} variant="body2">{inCm}</Typography>}
          {inKg && <Typography color={'Gray'} component="caption" fontSize={'10px'} variant="body2">{inKg}</Typography>}
          {valid && (<Typography color="#EF627A" component="caption" variant="body2">*</Typography>)}
        </Box>
      )}
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        name={name}
        value={value}
        onChange={onChange ? onChange : (e) => {
          const value = e.target.value;
          const strippedValue = value.replace(regex, "");
          const modifiedEvent = {
            ...e,
            target: {
              ...e.target,
              name: name,
              value: strippedValue,
            },
          };
          onChange(modifiedEvent);
        }}
        type={
          type === "password"
            ? showPassword && showPasswordToggle
              ? "text"
              : type
            : type
        }
        placeholder={placeholder}
        sx={{ height: height, width: width, bgcolor: bgcolor }}
        multiline={multiline}
        rows={rows}
        className={`${classes?.main} ${className}`}
        onInput={onInput}
        onPaste={onPaste}
        inputProps={{
          ...inputProps,
          style: { fontSize: placeholderFontSize || "inherit" },
        }}
        onKeyDown={onKeyDown}
        fullWidth={fullWidth ? fullWidth : ''}
        defaultValue={defaultValue}
        helperText={helperText}
        onDrag={onDrag}
        InputProps={{
          ...(showPasswordToggle && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        onBlur={onBlur}
        disabled={disabled || false}
      />
    </>
  );
};

export default TextFieldComponent;
