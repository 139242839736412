import { Badge, Box, FormControlLabel, Grid, IconButton, Input, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import CommonHeader from '../../Components/Common/CommonHeader';
import { useDispatch } from 'react-redux';
import StyledTableRow from '../../Components/Common/StyledTableRow';
import PaperContainer from '../../Components/Common/StyledTableCell';
import StyledTableCell from '../../Components/Common/StyledTableCell';
import CustomTooltip from '../../Components/Common/CustomTooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DataNotFound from '../../Components/Common/dataNotFound';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getTopic } from '../../Redux/Slices/topicSlice';
import ErrorHandler, { trimAndRemoveExtraSpaces } from '../../Utils/handler';
import CustomDialog from '../../Components/Common/CustomDialog';
import ButtonContained from '../../Components/Common/ButtonContained';
import { getSubjects } from '../../Redux/Slices/subjectSlice';
import AddUpdateLecture from '../../Components/Lecture/AddUpdateLecture';
import { createLecture, getLecture, getLecturesById, updateLecture, updateLectureSequence, } from '../../Redux/Slices/LectureSlice';
import CustomPagination from '../../Components/Common/CustomPagination';
import { deleteFile, uploadFile } from '../../Redux/Actions/action';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import WidgetLoader from '../../Components/Common/widgetLoader';
import { toast } from 'react-toastify';
import AutoCompleteSearch from '../../Components/Common/AutoCompleteSearch';
import SwapVerticalCircleRoundedIcon from '@mui/icons-material/SwapVerticalCircleRounded';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ReadMore from '../../Components/Common/ReadMore';

function Lecture() {
    //Hooks 
    const theme = useTheme();
    const dispatch = useDispatch()

    //States
    const [isOpen, setIsOpen] = React.useState(false);
    const [isEdit, setisEdit] = React.useState(false);
    const [lectureDataList, setLectureDataList] = React.useState([])
    const [errors, setErrors] = React.useState({})
    const [data, setData] = React.useState({ 'isPaid': true, });
    const [loading, setLoading] = React.useState(false)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [oldLectureFileUrl, setOldLectureFileUrl] = React.useState("");
    const [subjects, setSubjects] = React.useState([])
    const [topics, setTopics] = React.useState([])
    const [isSequence, setIsSequence] = React.useState({ idx: "", open: false })

    const subjectOptions = subjects?.length > 0 ? subjects?.map((subject) => subject?.subjectName) : [];
    const topicOptions = topics?.filter((ele) => ele?.subjectDetails?.subjectName === data?.subjectName)?.map((value) => value?.topicName);


    //  Validations 
    const validation = () => {
        let isValid = true;
        let error = {};

        if (!data?.subjectName) {
            isValid = false;
            error.subjectName = '*Subject name is required';
        }
        if (!data?.topicName) {
            isValid = false;
            error.topicName = '*Topic name is required';
        }
        if (!data?.lectureName) {
            isValid = false;
            error.lectureName = '*Lecture name is required';
        }
        if (data?.lectureVideoLink) {
            let value = data?.lectureVideoLink;
            // const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})(\S+)?$/;
            const googleDriveRegex = /(?:drive\.google\.com\/file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)/;

            // Validate the URL
            // const isYoutubeValid = youtubeRegex.test(value);
            const isGoogleDriveValid = googleDriveRegex.test(value);
            if (!isGoogleDriveValid) {
                isValid = false;
                error.lectureVideoLink = '*Please enter valid google drive url';
            } else {
                error.lectureVideoLink = '';
            }
        }

        setErrors(error);
        return isValid;
    }

    console.log(errors, 'error')

    //  Handlers 
    const getSubjectId = subjects?.filter((subject) => subject?.subjectName === data?.subjectName);
    const getTopicId = topics?.filter((topic) => topic?.topicName === data?.topicName);

    const handleClear = () => {
        setIsOpen(false);
        setisEdit(false);
        setData({ ...data, lectureDescription: null, lectureName: "", isPaid: true, lectureVideoLink: "", fileData: "", lectureFileUrl: "", lectureFileName: "" });
        setErrors({});
        setOldLectureFileUrl('');
        setIsSequence({ idx: "", open: false });
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAutoCompleteChange = (name, newValue) => {
        if (name === "subjectName") {
            setData(prevState => ({
                ...prevState,
                subjectName: newValue,
                topicName: "",
            }));
        } else {
            setData(prevState => ({
                ...prevState,
                [name]: newValue
            }));
        }
    };

    const handleContentChange = (content) => {
        console.log("content---->", content);
        setData(prevState => ({
            ...prevState,
            lectureDescription: content
        }));
    };

    const handlePDFUpload = (acceptFile) => {
        const fileData = acceptFile?.target?.files?.[0]
        if (fileData) {
            const validType = 'application/pdf';

            if (fileData.type !== validType) {
                setErrors(errors => ({
                    ...errors,
                    lecturePDFSize: 'Please upload a PDF file.',
                }));
                return;
            }
            if (fileData?.size < 10485760) {
                // _uploadFile(fileData);
                console.log(fileData, data?.lectureFileUrl, 'fileData')
                setData(prevData => ({
                    ...prevData,
                    fileData: fileData,
                    lectureFileName: fileData?.name,
                    lectureFileUrl: URL.createObjectURL(fileData),
                }))
                setErrors(errors => ({
                    ...errors,
                    lecturePDFSize: '',
                }));
            }
            else {
                setErrors(errors => ({
                    ...errors,
                    lecturePDFSize: 'File size should be less than 10mb.',
                }));
                setData(prevData => ({
                    ...prevData,
                    fileData: null,
                    lectureFileName: "",
                    lectureFileUrl: "",
                }))
            }
        }
    };

    const handelDeleteFile = () => {
        setData(prevState => ({ ...prevState, lectureFileUrl: "", lectureFileName: "", fileData: "" }));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    //  API Calling 

    const _getLecture = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: searchText || "",
            subjectId: getSubjectId?.[0]?._id,
            topicId: getTopicId?.[0]?._id,
        };
        try {
            const result = await dispatch(getLecture(body))
            console.log(result, 'Lecture')
            const error = ErrorHandler(result)
            if (error) {
                setLectureDataList(result?.payload?.data)
                // handleClear()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const validateSequence = (previousSequence) => {
        if (data?.sequence !== previousSequence) {
            return true
        } else {
            toast.error("Sequence you entered is same !");
            handleClear()
            return false
        }
    }

    const _updateLectureSequence = async (id) => {
        setLoading(true);
        try {
            const result = await dispatch(updateLectureSequence({ _id: id, sequence: +data?.sequence, }))
            const error = ErrorHandler(result)
            if (error) {
                toast.success(result?.payload?.message);
                _getLecture()
                handleClear()
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false)
        }
    }

    const createUpdateLecture = async () => {
        if (validation()) {
            dispatch(showLoader(true))
            // setLoading(true);
            try {

                let body = {};
                if (data?.fileData) {
                    const formData = new FormData();
                    formData.append('image', data?.fileData);

                    const result = await dispatch(uploadFile(formData)).unwrap();
                    if (result.success) {
                        setData(prevState => ({
                            ...prevState,
                            lectureFileUrl: result.data?.data?.image,
                            lectureFileName: result.data?.data?.image?.split("image/")?.[1]
                        }));
                        body.lecturePDF = result.data?.data?.image;
                        setErrors(errors => ({
                            ...errors,
                            lectureImageSize: '',
                            lectureFileUrl: '',

                        }));
                    } else {
                        console.error('File upload error:', result.error);
                        return;
                    }
                }

                if (oldLectureFileUrl && oldLectureFileUrl !== data?.lectureFileUrl) {
                    _deleteFile(oldLectureFileUrl)
                }
                body = {
                    "subjectId": getSubjectId?.[0]?._id,
                    "topicId": getTopicId?.[0]?._id,
                    "lectureName": trimAndRemoveExtraSpaces(data?.lectureName),
                    "lectureDescription": data?.lectureDescription || null,
                    "lecturePDF": body.lecturePDF ? body.lecturePDF : data?.lectureFileUrl || null,
                    "lectureVideoLink": data?.lectureVideoLink || null,
                    "isPaid": data?.isPaid
                }
                if (isEdit && data?.id) {
                    body._id = data?.id
                }
                const fn = isEdit ? updateLecture : createLecture;
                const result = await dispatch(fn(body))
                const error = ErrorHandler(result)
                if (error) {
                    toast.success(result?.payload?.message);
                    handleClear()
                    // _getLecture()
                }
            } catch (error) {
                toast.error(error);
                console.log(error)
            } finally {
                dispatch(showLoader(false))
                // setLoading(false)
            }
        }
    }

    const _getLecturesById = async (id) => {
        dispatch(showLoader(true));
        try {
            const body = `/${id}`
            const result = await dispatch(getLecturesById(body))
            console.log(result?.payload?.data, 'byIdque')
            const error = ErrorHandler(result)
            if (error) {
                const lecture = result?.payload?.data;
                console.log(lecture, 'akki')
                setData({
                    id: lecture?._id,
                    subjectName: lecture?.subjectDetails?.subjectName,
                    topicName: lecture?.topicDetails?.topicName,
                    lectureName: lecture?.lectureName,
                    lectureDescription: lecture?.lectureDescription,
                    lectureVideoLink: lecture?.lectureVideoLink,
                    lectureFileUrl: lecture?.lecturePDF,
                    lectureFileName: lecture?.lecturePDF?.split("image/")?.[1],
                    isPaid: lecture?.isPaid
                });
                setOldLectureFileUrl(lecture?.lecturePDF);
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false));
        }
    }

    const _getSubjects = async () => {
        try {
            const result = await dispatch(getSubjects())
            const error = ErrorHandler(result)
            if (error) {
                const config = result?.payload?.data?.response
                console.log(config, "config")
                if (config?.length > 0) {
                    setSubjects(config)
                    setData({
                        ...data,
                        subjectName: config?.[0]?.subjectName || '',
                    });
                }

            }
        } catch (error) {
            console.log(error)
        }
    }

    const _getTopic = async () => {
        try {
            const result = await dispatch(getTopic())
            const error = ErrorHandler(result)
            if (error) {
                const config = result?.payload?.data?.response
                if (config?.length > 0) {
                    setTopics(config)
                    const filteredTopics = config?.filter((ele) => ele?.subjectDetails?.subjectName === data?.subjectName);
                    if (filteredTopics?.length > 0) {
                        const topicName = filteredTopics?.[0]?.topicName || '';
                        setData({
                            ...data,
                            topicName: topicName || '',
                        });
                    } else {
                        setLectureDataList([])
                    }
                }

            }
        } catch (error) {
            console.log(error)
        }
    }

    const _deleteFile = async (fileurl) => {
        try {
            let body = {
                "url": fileurl,
            }
            const result = await dispatch(deleteFile(body))
            const error = ErrorHandler(result)
            if (error) {
                setData(prevState => ({ ...prevState, lectureFileUrl: "", lectureFileName: "", fileData: "" }));
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        _getSubjects()
    }, [])

    useEffect(() => {
        if (data?.subjectName) _getTopic()
    }, [data?.subjectName])

    useEffect(() => {
        if (data?.subjectName && data?.topicName && !isOpen) _getLecture()
    }, [isOpen, data?.subjectName, data?.topicName, rowsPerPage, page, searchText])

    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12}>
                    <CommonHeader
                        title="Lecture List"
                        onClick={() => setIsOpen(true)}
                        btnText="Add Lecture"
                        disableButton={(getSubjectId?.[0]?._id && getTopicId?.[0]?._id) ? false : true}
                        handleSearch={(e) => { setPage(1); setSearchText(e); }}
                        otherComponent={
                            <>
                                <Box width={'250px'}>
                                    <AutoCompleteSearch
                                        width="100%"
                                        textLabel="Subject"
                                        placeholder="Search Subject"
                                        name="subjectName"
                                        handleChange={(newValue) => handleAutoCompleteChange("subjectName", newValue)}
                                        options={subjectOptions}
                                        value={data?.subjectName}
                                        isSearchIcon={false}
                                    />
                                </Box>
                                <Box width={'250px'}>
                                    <AutoCompleteSearch
                                        width="100%"
                                        textLabel="Topic"
                                        placeholder="Search Topic"
                                        name="topicName"
                                        handleChange={(newValue) => handleAutoCompleteChange("topicName", newValue)}
                                        options={topicOptions}
                                        value={data?.topicName}
                                        isSearchIcon={false}
                                    />
                                </Box>
                            </>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer sx={{ width: "100%" }}>
                        {!loading ? (
                            <Table sx={{ minWidth: 700 }}>
                                <TableHead component={PaperContainer} >
                                    <StyledTableRow>
                                        <StyledTableCell align='left'>No.</StyledTableCell>
                                        <StyledTableCell align='left'>Sequence</StyledTableCell>
                                        <StyledTableCell align='left'>Lecture Name</StyledTableCell>
                                        <StyledTableCell align="left">Lecture Description</StyledTableCell>
                                        <StyledTableCell align="left">Created At</StyledTableCell>
                                        <StyledTableCell align="center">Type</StyledTableCell>
                                        <StyledTableCell align="right">Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {lectureDataList?.response?.length > 0 ? lectureDataList?.response?.map((lecture, index) => {
                                        console.log(lecture?.lectureDescription, "lecture?.lectureDescription")
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell >{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <UpdateSequenceComponent
                                                        isSequence={isSequence}
                                                        setIsSequence={setIsSequence}
                                                        index={index}
                                                        setData={setData}
                                                        data={data}
                                                        value={lecture}
                                                        handleChangeInput={handleChangeInput}
                                                        onClick={() => { if (validateSequence(lecture?.sequence)) _updateLectureSequence(lecture?._id) }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>{lecture?.lectureName}</StyledTableCell>
                                                <StyledTableCell>{lecture?.lectureDescription ? <ReadMore content={lecture?.lectureDescription} maxLength={160} /> : "-"}</StyledTableCell>
                                                <StyledTableCell>{lecture?.createdAt ? new Date(lecture?.createdAt).toLocaleDateString() : 'N/A'}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Box display={'flex'} justifyContent={'center'}>
                                                        <Typography width={'70px'} bgcolor={lecture?.isPaid ? theme.palette.success.main : theme.palette.bgInfo.main} borderRadius={8} color={'whitesmoke'} fontSize={'14px'}>
                                                            {lecture?.isPaid ? 'Paid' : 'Free'}
                                                        </Typography>
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <CustomTooltip title={'Edit'}>
                                                        <IconButton size='small' aria-label="edit" sx={{ backgroundColor: theme.palette.bgInfo.secondary, color: theme.palette.bgInfo.main, }} onClick={() => {
                                                            setIsOpen(true);
                                                            setisEdit(true);
                                                            _getLecturesById(lecture?._id)
                                                        }}>
                                                            <EditOutlinedIcon fontSize='small' />
                                                        </IconButton>
                                                    </CustomTooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }) :
                                        <StyledTableRow>
                                            <TableCell colSpan={12}>
                                                <DataNotFound title="No Lecture Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                            </TableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        ) : (
                            <WidgetLoader />
                        )}
                    </TableContainer>
                </Grid>
                {lectureDataList?.count > 0 &&
                    <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(lectureDataList?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />

                    </Grid>
                }
                <CustomDialog
                    isOpen={isOpen}
                    handleClose={handleClear}
                    title={`${isEdit ? "Update" : "Add"} Lecture`}
                    width='130vh'
                    data={data}
                    setData={setData}
                    action={
                        <>
                            <Stack direction="row" justifyContent="flex-end" mr={2} >
                                <ButtonContained height={'35px'} padding={'6px 10px'} text={`${isEdit ? "Update" : "Add"} Lecture`} onClick={createUpdateLecture} />
                            </Stack>
                        </>
                    }
                    paid={<>
                        <FormControlLabel
                            control={<Switch checked={data?.isPaid} onChange={(e) => setData(prevState => ({ ...prevState, isPaid: e.target.checked, }))} color="primary" />}
                            label={<Typography color={data?.isPaid ? theme.palette.success.main : theme.palette.bgInfo.main} fontWeight={'bold'} variant="body1">
                                {data?.isPaid ? 'Paid' : 'Free'}
                            </Typography>}
                        />
                    </>}
                >
                    <AddUpdateLecture
                        handleClose={handleClear}
                        isEdit={isEdit}
                        setData={setData}
                        handleChangeInput={(e) => handleChangeInput(e)}
                        data={data}
                        handlePDFUpload={handlePDFUpload}
                        handleContentChange={handleContentChange}
                        deleteFile={handelDeleteFile}
                        errors={errors}
                    />
                </CustomDialog>
            </Grid>
        </>
    )
}

const UpdateSequenceComponent = ({ isSequence, handleChangeInput, setIsSequence, setData, value, data, index, onClick }) => {
    return (
        <Box display={'flex'} alignItems={'center'} width={'70px'}>
            {(isSequence?.open && isSequence?.idx === index) ?
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2}>
                    <Input defaultValue={value?.sequence} inputProps={{ 'aria-label': 'description' }} name='sequence' onChange={handleChangeInput} />
                    <TaskAltIcon fontSize='small' color='success' sx={{ cursor: "pointer", }} onClick={onClick} />
                </Box>
                :
                <CustomTooltip title={'Update Sequence'}>
                    <Badge badgeContent={value?.sequence} color="warning" sx={{
                        "& .MuiBadge-badge": {
                            height: '17px',
                            fontSize: '10px'
                        }
                    }}>
                        <SwapVerticalCircleRoundedIcon color='primary' fontSize='medium' onClick={() => { setIsSequence({ idx: index, open: true }); setData({ ...data, sequence: value?.sequence }) }} sx={{ cursor: 'pointer' }} />
                    </Badge>
                </CustomTooltip>
            }
        </Box>
    )

}

export default Lecture;
