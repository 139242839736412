import React, { useEffect } from 'react';
import { Box, Grid, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, Tabs } from '@mui/material';
// components
import StyledTableRow from '../../Components/Common/StyledTableRow';
import StyledTableCell from '../../Components/Common/StyledTableCell';
import PaperContainer from '../../Components/Common/StyledTableCell';
import { useDispatch } from 'react-redux';
import ErrorHandler from '../../Utils/handler';
import { activeInactiveUser, getUsers } from '../../Redux/Actions/action';
import DataNotFound from '../../Components/Common/dataNotFound';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import dayjs from "dayjs"
import CommonHeader from '../../Components/Common/CommonHeader';
import CustomPagination from '../../Components/Common/CustomPagination';
import WidgetLoader from '../../Components/Common/widgetLoader';
import { toast } from 'react-toastify';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CustomTooltip from '../../Components/Common/CustomTooltip';
import CustomAlertPopup from '../../Components/Common/CustomAlertPopup';
import { responseMessage } from '../../Components/Common/customMessage';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Client = () => {
    //Hooks
    const dispatch = useDispatch()

    //State
    const [userData, setUserData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState({
        data: null,
        open: false
    });
    const [selectedTab, setSelectedTab] = React.useState(0);

    //Handler
    const getUsersDetails = async (loader = true) => {
        if (loader) setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: searchText || "",
            isSubscribed: selectedTab === 0 ? true : false,
        };
        try {
            const result = await dispatch(getUsers(body))
            console.log(result?.payload?.data?.data?.response)
            const error = ErrorHandler(result)
            if (error) {
                setUserData(result?.payload?.data?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            if (loader) setLoading(false)
        }
    }

    const _activeInactiveUser = async (id, ischecked) => {
        try {
            let body = {
                _id: id,
                isBlock: ischecked,
            }
            const result = await dispatch(activeInactiveUser(body));
            const error = ErrorHandler(result)
            if (error) {
                toast.success(result?.payload?.message);
                await getUsersDetails(false);
            }
        } catch (error) {
            toast.error(error.message);
            console.log(error);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setPage(1)
    };

    useEffect(() => {
        getUsersDetails()
    }, [rowsPerPage, page, searchText, selectedTab])

    return (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12}>
                <CommonHeader title="User List" handleSearch={(e) => { setPage(1); setSearchText(e); }} />
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
                <Grid item xs={12}>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        <Tab label="Subscribed" {...a11yProps(0)} />
                        <Tab label="Not Subscribed" {...a11yProps(1)} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer sx={{ width: "100%" }}>
                        {!loading ? (
                            <Table sx={{ minWidth: 700 }}>
                                <TableHead component={PaperContainer} >
                                    <StyledTableRow>
                                        <StyledTableCell align='left'>No.</StyledTableCell>
                                        <StyledTableCell>First Name</StyledTableCell>
                                        <StyledTableCell align="left">Last Name</StyledTableCell>
                                        <StyledTableCell align="left">Email</StyledTableCell>
                                        <StyledTableCell align="left">Phone Number</StyledTableCell>
                                        <StyledTableCell align="left">Created At</StyledTableCell>
                                        <StyledTableCell align="center">Active/Inactive</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {userData?.response?.length > 0 ? userData?.response?.map((row, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.firstName}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.lastName}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.email}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.phoneNumber}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{dayjs(row?.createdAt).format('DD/MM/YYYY')}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <Switch
                                                            checked={!row?.isBlock}  // Show the active/inactive state
                                                            onChange={(e) => setIsConfirmationOpen({ data: row, open: true })}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        {row?.blockReason &&
                                                            <CustomTooltip title={row?.blockReason}>
                                                                <TextsmsIcon fontSize='small' color='error' />
                                                            </CustomTooltip>
                                                        }
                                                    </Box>

                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }) :
                                        <StyledTableRow>
                                            <TableCell colSpan={12}>
                                                <DataNotFound title="No Users Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                            </TableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        ) : (
                            <WidgetLoader />
                        )}
                    </TableContainer>
                </Grid>
            </Grid>
            {
                userData?.count > 0 &&
                <Grid item xs={12}>
                    <CustomPagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        count={Math.ceil(userData?.count / rowsPerPage)}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage} />

                </Grid>
            }
            {isConfirmationOpen.open && (
                <CustomAlertPopup
                    isOpen={isConfirmationOpen.open}
                    handleClose={() => setIsConfirmationOpen({ ...isConfirmationOpen, open: false })}
                    title={'Confirmation'}
                    subText={
                        isConfirmationOpen.data.isBlock ? (
                            isConfirmationOpen.data.blockReason === "Account deactivated by user" ? (
                                responseMessage.UserDeactivatedMessage
                            ) : isConfirmationOpen.data.blockReason === "Blocked due to multiple device logins" ? (
                                responseMessage.UserBlockDueToMulitpleLogins
                            ) : (
                                'Are you sure you want to activate the account?'
                            )
                        ) : (
                            'Are you sure you want to inactive the account?'
                        )
                    }
                    btnText={isConfirmationOpen.data.isBlock ? 'Activate' : 'Deactivate'}
                    onSave={async () => {
                        await _activeInactiveUser(isConfirmationOpen.data._id, !isConfirmationOpen.data.isBlock);
                        setIsConfirmationOpen({ ...isConfirmationOpen, open: false }); // Close the popup
                    }}
                    maxWidth={'xs'}
                />
            )}
        </Grid >
    )
};

export default Client;
