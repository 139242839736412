import React, { useState } from 'react';
import { Grid, } from '@mui/material';
import TextFieldComponent from '../Common/TextFieldComponent';
import FilePreviewModel from '../Common/FilePreviewModel';
import ErrorLog from '../Common/ErrorLog';
import CommonTextEditor from '../Common/CommonTextEditor';

function AddUpdateLecture({ data, setData, handleChangeInput, handleContentChange, handlePDFUpload, errors, deleteFile }) {

    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <TextFieldComponent
                        width="100%"
                        text="Lecture Name"
                        placeholder="Enter Lecture Name"
                        onChange={(e) => handleChangeInput(e)}
                        name="lectureName"
                        value={data?.lectureName}
                        valid
                    />
                    {data?.lectureName ? "" : <ErrorLog text={errors?.lectureName} />}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextFieldComponent
                        width="100%"
                        text="Lecture Description"
                        placeholder="Enter Lecture Description"
                        onChange={(e) => handleChangeInput(e)}
                        name="lectureDescription"
                        value={data?.lectureDescription}
                        multiline
                        rows={3}
                        subText="(Optional)"
                        helperText="This description will only used for notification content only.user will see in notification after lecture added !"
                    />
                    {/* <CommonTextEditor
                        text="Lecture Description"
                        handleContentChange={handleContentChange}
                        value={data?.lectureDescription}
                        valid
                    /> */}
                    {data?.lectureDescription !== "<p><br></p>" ? "" : <ErrorLog text={errors?.lectureDescription} />}
                </Grid>
                <Grid item xs={12} sm={12} >
                    <TextFieldComponent
                        width="100%"
                        text="Video URL"
                        size="small"
                        name='lectureVideoLink'
                        placeholder="Enter Lecture Video URL"
                        value={data?.lectureVideoLink}
                        onChange={handleChangeInput}
                        onPaste={(e) => {
                            e.preventDefault();
                            const value = e?.clipboardData?.getData("text");
                            let videoId = "";
                            let finalEmbedLink = "";

                            // YouTube link handling
                            const youtubeMatch = /watch\?v=(.+)/.exec(value) || /youtu\.be\/(.+)/.exec(value) || /embed\/(.+?)"/.exec(value);
                            if (youtubeMatch) {
                                videoId = youtubeMatch[1];
                                finalEmbedLink = `https://www.youtube.com/embed/${videoId}`;
                            }

                            // Google Drive link handling
                            const googleDriveMatch = /(?:drive\.google\.com\/file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)/.exec(value);
                            if (googleDriveMatch) {
                                const driveId = googleDriveMatch[1];
                                finalEmbedLink = `https://drive.google.com/file/d/${driveId}/preview`;
                            }

                            // Update the videoLink field
                            if (finalEmbedLink) {
                                setData(prevState => ({
                                    ...prevState,
                                    lectureVideoLink: finalEmbedLink
                                }));
                            } else {
                                console.error("Invalid URL:", value);
                            }
                        }}
                        subText='(Optional)'
                        helperText="Paste a Google Drive URL to automatically convert it to an embed URL."

                    />
                    {!data?.lectureVideoLink ? "" : <ErrorLog text={errors?.lectureVideoLink} />}
                </Grid>
                {data?.lectureVideoLink && <Grid item xs={12}>
                    <iframe
                        src={data?.lectureVideoLink}
                        width={'100%'}
                        height="315"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={data?.lectureVideoLink}
                    />
                </Grid>}
                <Grid item xs={12} sm={12} mt={1}>
                    <FilePreviewModel
                        isOpen={isOpen}
                        onClick={() => setIsOpen(true)}
                        handleClose={() => setIsOpen(false)}
                        id={"pdf-upload"}
                        htmlFor={"pdf-upload"}
                        accept={"application/pdf"}
                        name="lecturePDF"
                        fileLink={data?.lectureFileUrl}
                        title="Lecture PDF"
                        btnText="Upload PDF"
                        label="Upload PDF"
                        fileName={data?.lectureFileName}
                        onChange={handlePDFUpload}
                        deleteFile={deleteFile}
                        data={data}
                        subText='(Optional)'
                        isPdf
                    />
                    {/* {data?.lectureFileName ? "" : <ErrorLog text={errors?.lectureFileName} />} */}
                    {(errors?.lecturePDFSize) && <ErrorLog text={errors?.lecturePDFSize} />}
                </Grid>
            </Grid>
        </>
    );
}

export default AddUpdateLecture;
