import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from 'tss-react/mui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { Avatar,Stack, useMediaQuery } from '@mui/material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SubjectIcon from '@mui/icons-material/Subject';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import sessionStoreUtil from '../../ApiSetUp/LocalStorage';
import CustomDialog from './CustomDialog';
import CustomAlertPopup from './CustomAlertPopup';
import Setting from '../Setting/Setting';
import ButtonContained from './ButtonContained';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    // overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    border: 'none',
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        borderColor: theme.palette.primary.main,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
const useStyles = makeStyles()((theme) => {
    return {
        non_active_tab: {
            color: theme.palette.bgLightWhite.main,
        },
        active_tab: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            fontWeight: 700,
            borderRadius: "30px",
            "&::before": {
                content: '""',
                position: 'absolute',
                top: '-50px',
                right: '0px',
                width: '50px',
                height: '50px',
                background: 'transparent',
                borderRadius: '50%',
                boxShadow: `25px 25px 0 ${theme.palette.background.default}`,
                zIndex: "-1",
            },
            "&::after": {
                content: '""',
                position: 'absolute',
                bottom: '-50px',
                right: '0px',
                width: '50px',
                height: '50px',
                background: 'transparent',
                borderRadius: '50%',
                boxShadow: `25px -25px 0 ${theme.palette.background.default}`,
                zIndex: "-1",
            }
        },
    }
})

const MenuList = [
    {
        title: "Dashboard",
        icon: <DashboardOutlinedIcon />,
        link: "/dashboard",
    },
    {
        title: "User",
        icon: <Person2OutlinedIcon />,
        link: "/user",
    },
    {
        title: "Subject",
        icon: <SubjectIcon />,
        link: "/subject",
    },
    {
        title: "Topic",
        icon: <TopicOutlinedIcon />,
        link: "/topic",
    },
    {
        title: "Lecture",
        icon: <LaptopChromebookIcon />,
        link: "/lecture",
    },
    {
        title: "Question",
        icon: <HelpOutlineOutlinedIcon />,
        link: "/question",
    },
    {
        title: "Note",
        icon: <NoteAddIcon />,
        link: "/note",
    },
    {
        title: "User Query",
        icon: <ReceiptLongOutlinedIcon />,
        link: "/userQuery",
    }
];
const Layout = ({ children }) => {
    const theme = useTheme()
    const navigate = useNavigate();
    const location = useLocation();
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedMenu, setSelectedMenu] = React.useState('Dashboard');
    const [settingOpen, setSettingOpen] = React.useState(false);
    const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
    const dispatch = useDispatch()
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
    const [data, setData] = React.useState({
        page1: {
            title: '',
            description: '',
        },
        page2: {
            title: '',
            description: '',
        },
        page3: {
            title: '',
            description: '',
        }
    });
    const [errors, setErrors] = React.useState({})

    
    //  Validations 
    const validation = () => {
        let isValid = true;
        let error = {};

        if (!data?.page1?.title) {
            isValid = false;
            error.title1 = 'Title of page1 is required';
        }
        if (!data?.page1?.description) {
            isValid = false;
            error.description1 = 'description of page1 is required';
        }
        if (!data?.page1?.fileUrl) {
            isValid = false;
            error.Image1 = 'Image of page1 is required';
        }
        if (!data?.page2?.title) {
            isValid = false;
            error.title2 = 'Title of page2 is required';
        }
        if (!data?.page2?.description) {
            isValid = false;
            error.description2 = 'description of page2 is required';
        }
        if (!data?.page2?.fileUrl) {
            isValid = false;
            error.Image2 = 'Image of page2 is required';
        }
        if (!data?.page3?.title) {
            isValid = false;
            error.title3 = 'Title of page3 is required';
        }
        if (!data?.page3?.description) {
            isValid = false;
            error.description3 = 'description of page3 is required';
        }
        if (!data?.page3?.fileUrl) {
            isValid = false;
            error.Image3 = 'Image of page3 is required';
        }

        setErrors(error);
        return isValid;
    }

    React.useEffect(() => {
        if (isTablet) {
            setOpen(false);
        }
    }, [isTablet]);

    const handleMenuClick = (menuName) => {
        setSelectedMenu(menuName);
    };

    const handleLogoutClick = () => {
        setTimeout(() => {
            setLogoutDialogOpen(true);
        }, 200);

    }
    const handleSetting = () => {
        setSettingOpen(true);
    }

    const handleLogoutConfirm = () => {
        setLogoutDialogOpen(false);
        dispatch(showLoader(true));
        setTimeout(() => {
            sessionStoreUtil.removeLSItem('authtoken');
            navigate('/login');
            dispatch(showLoader(false));
        }, 1000)
    }

    React.useEffect(() => {
        setData({
            page1: {
                title: '',
                description: '',
            },
            page2: {
                title: '',
                description: '',
            },
            page3: {
                title: '',
                description: '',
            }
        })
    }, [settingOpen])

    return (
        <Box sx={{ display: 'flex', }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ backgroundColor: theme?.palette?.bgLightWhite?.main }} open={open} elevation={0}>
                <Toolbar variant='dense' sx={{ justifyContent: 'space-between' }} >
                    <Stack direction="row" alignItems="center" ml={open ? 0 : 7}>
                        <Typography color='primary' variant="h6" noWrap component="div">
                            {selectedMenu}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt="Admin" src="/static/images/avatar/1.jpg" sx={{
                            width: '35px', height: '35px',
                        }} />
                        <Typography color='primary'>Admin</Typography>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}
                sx={{
                    flexShrink: 0,
                    zIndex: 1202,
                    '& .MuiDrawer-paper': {
                        borderRadius: "0 27px 27px 0",
                        background: theme.palette.primary.main,
                        boxSizing: 'border-box',
                        borderRight: "none",
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    },
                    '& .MuiList-root': {
                        paddingTop: '0px',
                    },
                }}>
                <DrawerHeader sx={{ display: 'flex', justifyContent: 'space-around', minHeight: '52px !important', }}>
                    {open && <img src='Images/logo.png' height={'38px'} alt='logo' style={{ marginLeft: 15 }} />}
                    <IconButton onClick={() => setOpen(!open)}>
                        {!open ? <MenuIcon sx={{ color: theme.palette.bgLightWhite.main, }} /> : <ChevronLeftIcon sx={{ color: theme.palette.bgLightWhite.main, zIndex: 9 }} />}
                    </IconButton>
                </DrawerHeader>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"} marginLeft={open ? "10px" : "5px"}>
                    <List>
                        {MenuList.map((menu, index) => {
                            return (
                                <ListItem key={index} disablePadding sx={{ display: 'block', "& .MuiListItemButton-root:hover": { backgroundColor: location.pathname === menu.link ? "#0000" : "" } }}
                                    className={location.pathname === menu.link ? classes.active_tab : classes.non_active_tab}
                                >
                                    <ListItemButton
                                        component={Link}
                                        to={menu.link}
                                        onClick={() => handleMenuClick(menu.title)}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                color: location.pathname !== menu.link ? '#fff' : theme.palette.primary.main
                                            }}
                                        >
                                            {menu.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={menu.title} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                    <List>
                        {/* <ListItem disablePadding sx={{ py: 0 }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', }} onClick={handleSetting} >
                                <ListItemIcon sx={{
                                    cursor: "pointer",
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: theme.palette.bgLightWhite.main
                                }}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                {open && <ListItemText sx={{ color: theme.palette.bgLightWhite.main }}>
                                    Setting
                                </ListItemText>}
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem disablePadding sx={{ py: 0 }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', }} onClick={handleLogoutClick}>
                                <ListItemIcon sx={{
                                    cursor: "pointer",
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: theme.palette.bgLightWhite.main
                                }}>
                                    <LogoutOutlinedIcon />
                                </ListItemIcon>
                                {open && <ListItemText sx={{ color: theme.palette.bgLightWhite.main }}>
                                    Logout
                                </ListItemText>}
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
            </Box>
            <CustomDialog
                isOpen={settingOpen}
                handleClose={() => setSettingOpen(false)}
                title={"Setting"}
                width='130vh'
                action={
                    <>
                        <Stack direction="row" justifyContent="flex-end" mr={2} >
                            <ButtonContained height={'35px'} padding={'6px 10px'} text={"save"} onClick={() => {validation(); setSettingOpen(false); console.log(data, 'data') }} />
                        </Stack>
                    </>
                }
            >
                <Setting
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                />
            </CustomDialog>
            <CustomAlertPopup
                isOpen={logoutDialogOpen}
                handleClose={() => setLogoutDialogOpen(false)}
                title={'Logout Confirmation'}
                subText={'Are you sure you want to logout?'}
                btnText={'Logout'}
                onSave={handleLogoutConfirm}
            />
        </Box>
    );
}

export default Layout;
