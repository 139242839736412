import React, { useEffect, useState } from 'react'
import CommonHeader from '../../Components/Common/CommonHeader';
import { Grid, IconButton, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import StyledTableRow from '../../Components/Common/StyledTableRow';
import StyledTableCell from '../../Components/Common/StyledTableCell';
import CustomTooltip from '../../Components/Common/CustomTooltip';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PaperContainer from '../../Components/Common/StyledTableCell';
import DataNotFound from '../../Components/Common/dataNotFound';
import { useTheme } from '@emotion/react';
import { activeInactiveNote, createNotes, deleteFile, getNotes, getNotesById, multipleUploadFile, updateNotes, uploadFile } from '../../Redux/Actions/action';
import ErrorHandler from '../../Utils/handler';
import { useDispatch } from 'react-redux';
import CustomPagination from '../../Components/Common/CustomPagination';
import CustomDialog from '../../Components/Common/CustomDialog';
import ButtonContained from '../../Components/Common/ButtonContained';
import AddUpdateNote from '../../Components/Note/AddUpdateNote';
import WidgetLoader from '../../Components/Common/widgetLoader';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import { toast } from 'react-toastify';
import ReadMore from '../../Components/Common/ReadMore';
function Note() {
    //hooks
    const theme = useTheme()
    const dispatch = useDispatch()
    //state
    const [isOpen, setIsOpen] = useState(false)
    const [isEdit, setisEdit] = React.useState(false);
    const [errors, setErrors] = React.useState({})
    const [noteDataList, setNoteDataList] = React.useState({})
    const [data, setData] = React.useState({ 'multiplefiles': [], })
    const [loading, setLoading] = React.useState(false)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [oldNoteFileUrl, setOldNoteFileUrl] = React.useState("");

    //Validation
    const validation = () => {
        let isValid = true;
        let error = {}
        if (!data?.title) {
            isValid = false;
            error.title = '*Title is required';
        }
        if (!data?.price) {
            isValid = false;
            error.price = '*Price is required';
        }
        else if (data.price <= 0) {
            isValid = false;
            error.priceZero = '*Price should be greater than zero';
        }
        if (!data?.description) {
            isValid = false;
            error.description = '*Description is required';
        }
        if (!data?.length) {
            isValid = false;
            error.length = '*Length is required';
        }
        else if (data.length <= 0) {
            isValid = false;
            error.lengthZero = '*Length should be greater than zero';
        }
        if (!data?.breadth) {
            isValid = false;
            error.breadth = '*Breadth is required';
        }
        else if (data.breadth <= 0) {
            isValid = false;
            error.breadthZero = '*Breadth should be greater than zero';
        }
        if (!data?.height) {
            isValid = false;
            error.height = '*Height is required';
        }
        else if (data.height <= 0) {
            isValid = false;
            error.heightZero = '*Height should be greater than zero';
        }
        if (!data?.weight) {
            isValid = false;
            error.weight = '*Weight is required';
        }
        else if (data.weight <= 0) {
            isValid = false;
            error.weightZero = '*Weight should be greater than zero';
        }
        // if (!data?.stockQuantity) {
        //     isValid = false;
        //     error.stockQuantity = '*Stock Quantity is required';
        // }
        // else if (data.stockQuantity <= 0) {
        //     isValid = false;
        //     error.stockQuantityZero = '*Stock Quantity should be greater than zero';
        // }
        if (!data?.fileUrl) {
            isValid = false;
            error.fileUrl = '*Image is required';
        }
        if (!data?.multiplefiles?.length) {
            isValid = false;
            error.multiplefiles = '*Please upload at least one file';
        }
        setErrors(error)
        return isValid;
    }

    //handler 

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (parseFloat(value) <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`${name}Zero`]: `${name.charAt(0).toUpperCase() + name.slice(1)} should be greater than zero`
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`${name}Zero`]: ''
            }));
        }

    };

    const handleImageUpload = (acceptFile) => {
        const fileData = acceptFile?.target?.files?.[0]

        if (fileData) {
            // Check file type
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validTypes.includes(fileData.type)) {
                setErrors(errors => ({
                    ...errors,
                    imageSize: 'Please upload an image file (JPEG, PNG, JPG).',
                    fileUrl: ''
                }));
                return;
            }
            if (fileData?.size < 10485760) {
                setData(prevData => ({
                    ...prevData,
                    fileData: fileData,
                    fileName: fileData?.name,
                    fileUrl: URL.createObjectURL(fileData)
                }))
                setErrors(errors => ({
                    ...errors,
                    imageSize: '',
                    fileUrl: ''
                }));
            }
            else {
                setErrors(errors => ({
                    ...errors,
                    imageSize: 'File size should be less than 10mb.',
                    fileUrl: ''
                }));
                setData(prevData => ({
                    ...prevData,
                    fileData: null,
                    fileName: '',
                    fileUrl: ''
                }))
            }
        }
    };

    const handleMultipleImageUpload = (acceptFile) => {
        const fileData = acceptFile?.target?.files
        const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const areAllFilesValidType = Array.from(fileData).every(file => validTypes.includes(file.type));
        const areAllFilesLessThan10MB = Array.from(fileData).every(file => file.size < 10485760);
        if (Object.values(fileData)?.length < 6) {
            if (areAllFilesValidType) {
                if (areAllFilesLessThan10MB) {

                    const filesWithId = Object.values(fileData).map((fileItem, index) => ({
                        id: index + 1,
                        file: fileItem
                    }));

                    const filesWithUrlAndName = filesWithId.map((fileItem) => ({
                        id: fileItem.id,
                        fileUrl: URL.createObjectURL(fileItem.file),
                        fileName: fileItem.file?.name
                    }));

                    setData(prevData => ({
                        ...prevData,
                        multiplefileList: filesWithId,
                        multiplefiles: filesWithUrlAndName
                    }));
                    setErrors(errors => ({
                        ...errors,
                        multipleImageSize: '',
                    }));
                }
                else {
                    setErrors(errors => ({
                        ...errors,
                        multipleImageSize: 'File size should be less than 10mb.',
                        multiplefiles: ''
                    }));
                    setData(prevData => ({
                        ...prevData,
                        multiplefileList: [],
                        multiplefiles: []
                    }));
                }
            } else {
                setErrors(errors => ({
                    ...errors,
                    multipleImageSize: 'Please upload image files (JPEG, PNG).',
                    multiplefiles: ''
                }));
            }
        } else {
            setErrors(errors => ({
                ...errors,
                multipleImageSize: 'You can upload max 5 images.',
                multiplefiles: ''
            }));
        }

    };

    const handelDeleteFile = (fileUrl, isMultiple, fileId) => {
        if (isMultiple) {
            setData(prevState => ({
                ...prevState,
                multiplefiles: prevState.multiplefiles?.filter((file) => file?.id !== fileId),
                multiplefileList: prevState.multiplefileList?.filter((fileData) => fileData?.id !== fileId)
            }));
        } else {
            setData(prevState => ({
                ...prevState,
                fileName: "",
                fileUrl: "",
                fileData: "",
            }));
        }
    }

    const handleClear = () => {
        setErrors({})
        setData({ 'multiplefiles': [], "fileData": "", "fileName": "", "fileUrl": "", "multiplefileList": [], "title": "" })
        setIsOpen(false);
        setisEdit(false);
        setOldNoteFileUrl('')
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    // API Calling
    const createUpdateNote = async () => {
        if (validation()) {
            dispatch(showLoader(true))

            try {
                let body1 = {};
                let body = {};
                if (data?.fileData) {
                    const formData = new FormData();
                    formData.append('image', data?.fileData);

                    const result = await dispatch(uploadFile(formData)).unwrap();
                    if (result.success) {
                        setData(prevState => ({
                            ...prevState,
                            fileUrl: result.data?.data?.image,
                            fileName: result.data?.data?.image?.split("image/")?.[1]
                        }));
                        body1.Image = result.data?.data?.image;
                        setErrors(errors => ({
                            ...errors,
                            imageSize: '',
                            fileUrl: ''
                        }));
                    } else {
                        console.error('File upload error:', result.error);
                        return;
                    }
                }

                if (data?.multiplefileList?.length > 0) {
                    const formData = new FormData();
                    data?.multiplefileList?.forEach(fileItem => {
                        formData.append('image', fileItem.file);
                    });

                    const result = await dispatch(multipleUploadFile(formData)).unwrap();
                    if (result.success) {
                        const newFilesData = result.data?.data?.map((item, index) => ({
                            id: index + 1,
                            fileUrl: item?.image || '',
                            fileName: item?.image?.split("image/")?.[1] || '',
                        }));
                        body1.imageList = newFilesData;
                        setData(prevState => ({
                            ...prevState,
                            multiplefiles: newFilesData
                        }));
                        setErrors(errors => ({
                            ...errors,
                            multipleImageSize: '',
                        }));
                    } else {
                        console.error('File upload error:', result.error);
                        return;
                    }
                }

                if (oldNoteFileUrl && oldNoteFileUrl !== data?.fileUrl) {
                    _deleteFile(oldNoteFileUrl);
                }
                body = {
                    "title": data?.title,
                    "image": body1.Image ? body1.Image : data?.fileUrl,
                    "subImage": body1.imageList ? body1.imageList : data?.multiplefiles,
                    "price": data?.price,
                    "description": data?.description,
                    // "stockQuantity": data?.stockQuantity,
                    "stockQuantity": 1,
                    "length": data?.length,
                    "breadth": data?.breadth,
                    "height": data?.height,
                    "weight": data?.weight,
                };
                if (isEdit && data?.id) {
                    body._id = data?.id;
                }
                const fn = isEdit ? updateNotes : createNotes;
                const result = await dispatch(fn(body))

                const error = ErrorHandler(result);
                if (error) {
                    toast.success(result?.payload?.message);
                    handleClear();
                    _getNotes()
                } else {
                    console.error('Update error:', error);
                }
            } catch (error) {
                toast.error(error.message || 'An error occurred');
                console.log('Error:', error);
            } finally {
                dispatch(showLoader(false));
            }
        }
    }

    const _getNotes = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: searchText || "",
        };
        try {
            const result = await dispatch(getNotes(body));
            const error = ErrorHandler(result)
            if (error) {
                setNoteDataList(result?.payload?.data)
            }
        }
        catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const _activeInactiveNote = async (id, isChecked) => {
        try {
            let body = {
                _id: id,
                isBlock: isChecked,
            };

            const result = await dispatch(activeInactiveNote(body));
            const error = ErrorHandler(result);
            if (error) {
                console.log(result?.payload?.message, 'message')
                toast.success(result?.payload?.message);
                await _getNotes(false);
            }
        } catch (error) {
            toast.error(error);
            console.log(error);
        }
    };

    const _getNotesById = async (id) => {
        dispatch(showLoader(true));
        try {
            const body = `/${id}`
            const result = await dispatch(getNotesById(body));
            const error = ErrorHandler(result)
            if (error) {
                const note = result?.payload?.data;
                console.log(note, 'noteee')
                setData({
                    id: note?._id,
                    title: note?.title,
                    fileUrl: note?.image,
                    fileName: note?.image?.split("image/")?.[1],
                    multiplefiles: note?.subImage,
                    price: note?.price,
                    description: note?.description,
                    length: note?.length,
                    breadth: note?.breadth,
                    height: note?.height,
                    weight: note?.weight,
                    // stockQuantity: note?.stockQuantity,
                })
                setOldNoteFileUrl(note?.image)
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false));
        }
    }

    const _deleteFile = async (fileUrl, isMultipleUpload) => {
        try {
            let body = {
                "url": fileUrl,
            }
            const result = await dispatch(deleteFile(body))
            const error = ErrorHandler(result)
            if (error) {
                if (isMultipleUpload) {
                    setData(prevState => ({
                        ...prevState,
                        multiplefiles: prevState.multiplefiles?.filter((file) => file?.fileUrl !== fileUrl)
                    }));
                } else {
                    setData(prevState => ({
                        ...prevState,
                        fileUrl: "",
                        fileName: "",
                        fileData: ""
                    }));
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        _getNotes()
    }, [rowsPerPage, page, searchText])
    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12}>
                    <CommonHeader
                        title="Note List"
                        onClick={() => setIsOpen(true)}
                        btnText="Add Note"
                        handleSearch={(e) => { setPage(1); setSearchText(e); }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer sx={{ width: "100%" }} >
                        {!loading ? (
                            <Table sx={{ minWidth: 700 }}>
                                <TableHead component={PaperContainer}>
                                    <StyledTableRow>
                                        <StyledTableCell align='left'>No.</StyledTableCell>
                                        <StyledTableCell align='left'>Title</StyledTableCell>
                                        <StyledTableCell align='left'>Price</StyledTableCell>
                                        <StyledTableCell align='center'>Description</StyledTableCell>
                                        <StyledTableCell align='center'>In Stock</StyledTableCell>
                                        {/* <StyledTableCell align='center'>Stock Quantity</StyledTableCell> */}
                                        <StyledTableCell align='left'>Created At</StyledTableCell>
                                        <StyledTableCell align="left">Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {noteDataList?.response?.length > 0 ? noteDataList?.response?.map((note, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{note?.title}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{note?.price}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row"><ReadMore content={note?.description} maxLength={80} /></StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Switch
                                                        checked={!note?.isBlock}
                                                        onChange={(e) => { _activeInactiveNote(note?._id, !e.target.checked); }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </StyledTableCell>
                                                {/* <StyledTableCell component="th" scope="row" align='center'>{note?.stockQuantity}</StyledTableCell> */}
                                                <StyledTableCell component="th" scope="row"> {note?.createdAt ? new Date(note?.createdAt).toLocaleDateString() : 'N/A'}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row" sx={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                                    <CustomTooltip title={'Edit'}>
                                                        <IconButton size='small' aria-label="edit" sx={{ backgroundColor: theme.palette.bgInfo.secondary, color: theme.palette.bgInfo.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                                            setIsOpen(true);
                                                            setisEdit(true);
                                                            _getNotesById(note?._id)
                                                        }}>
                                                            <EditOutlinedIcon fontSize='small' />
                                                        </IconButton>
                                                    </CustomTooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }) :
                                        <StyledTableRow>
                                            <TableCell colSpan={12}>
                                                <DataNotFound title="No Notes Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                            </TableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        ) : (
                            <WidgetLoader />
                        )}
                    </TableContainer>
                </Grid>
                {noteDataList?.count > 0 &&
                    <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(noteDataList?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />

                    </Grid>
                }
                {isOpen && <CustomDialog
                    isOpen={isOpen}
                    handleClose={handleClear}
                    title={`${isEdit ? "Update" : "Add"} Note`}
                    width={'100%'}
                    action={
                        <>
                            <Stack direction="row" justifyContent="flex-end" mr={2} >
                                <ButtonContained height={'35px'} padding={'6px 10px'} text={`${isEdit ? "Update" : "Add"} Note`} onClick={createUpdateNote} />
                            </Stack>
                        </>
                    }
                >
                    <AddUpdateNote
                        data={data}
                        handleChangeInput={(e) => handleChangeInput(e)}
                        handleImageUpload={handleImageUpload}
                        handleMultipleImageUpload={handleMultipleImageUpload}
                        // handleContentChange={handleContentChange}
                        deleteFile={handelDeleteFile}
                        errors={errors}
                        setData={setData}
                    />
                </CustomDialog>}
            </Grid>
        </>
    )
}

export default Note
