import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@emotion/react';
import { lightTheme } from './theme';
import Login from './Pages/Login';
import { ProtectedRoutes } from './Pages/Protected/ProtectedRoutes';
import Dashboard from './Pages/Dashboard';
import MainLayout from './Components/Common/MainLayout';
import User from './Pages/Users/user';
import Loader from './Components/Common/Loader';
import Subject from './Pages/Subject/Subject';
import Topic from './Pages/Topic/Topic';
import Lecture from './Pages/Lecture/Lecture';
import Questions from './Pages/Questions/Questions';
import Note from './Pages/Note/Note';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserQuery from './Pages/UserQuery/UserQuery';

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <ThemeProvider theme={lightTheme}>
          <Loader />
          <MainLayout>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route element={<ProtectedRoutes />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/user" element={<User />} />
                <Route exact path="/subject" element={<Subject />} />
                <Route exact path="/topic" element={<Topic />} />
                <Route exact path="/lecture" element={<Lecture />} />
                <Route exact path="/question" element={<Questions />} />
                <Route exact path="/note" element={<Note />} />
                <Route exact path="/userQuery" element={<UserQuery />} />
              </Route>
            </Routes>
          </MainLayout>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
