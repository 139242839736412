import React, { useState } from 'react';
import { Typography, Box, Link, useTheme } from '@mui/material';
import cleanHtml from '../../lib/cleanHtml';

const ReadMore = ({ content, maxLength, width }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme()
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const isContentLong = content?.length > maxLength;

  const displayContent = isExpanded || !isContentLong
    ? content
    : `${content?.slice(0, maxLength)}...`;

  return (
    <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'}>
      <Box
        component={Typography}
        variant='body2'
        width={width || '500px'}
        height="100%"
        margin={0}
        lineHeight={'1rem'}
        dangerouslySetInnerHTML={{ __html: cleanHtml(displayContent, false) }}
      />
      {isContentLong && (
        <Link onClick={toggleReadMore} underline='none' sx={{ color: theme.palette.bgInfo.main, cursor: 'pointer' }}>
          {isExpanded ? 'read less' : 'read more'}
        </Link>
      )}
    </Box>
  );
};



export default ReadMore;
