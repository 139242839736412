export const api = {

  //Admin Auth
  adminLogIn: "admin/login",

  //User Auth
  addUser: "admin/signup",
  getUsers: "admin/getUsers",
  activeInactiveUser: "admin/activeInactiveUser",

  //User Auth
  dashboardDetails: "admin/dashboardDetails",

  //subject 
  createSubject: "admin/createSubject",
  updateSubject: "admin/updateSubject",
  activeInactiveSubject: "admin/activeInactiveSubject",
  getSubjects: "admin/getSubjects",
  getSubjectsById: "admin/getSubjectById",

  //Topic
  createTopic: "admin/createTopic",
  updateTopic: "admin/updateTopic",
  updateTopicSequence: "admin/updateTopicSequence",
  getTopics: "admin/getTopics",
  getTopicsById: "admin/getTopicById",

  //Lecture
  createLecture: "admin/createLecture",
  updateLecture: "admin/updateLecture",
  updateLectureSequence: "admin/updateLectureSequence",
  getLectures: "admin/getLectures",
  getLecturesById: "admin/getLectureById",

  //Question
  createQuestion: "admin/createQuestion",
  updateQuestion: "admin/updateQuestion",
  getQuestions: "admin/getQuestions",
  getQuestionsById: "admin/getQuestionById",

  // Note
  createNote: "admin/createNote",
  updateNote: "admin/updateNote",
  getNotes: "admin/getNotes",
  activeInactiveNote: "admin/activeInactiveNote",
  getNotesById: "admin/getNoteById",

  //upload 
  uploadFile: "upload/image/attachment",
  deleteFile: "upload/delete_file",
  multipleUploadFile : "upload/image",

  //UserQueries
  getUserQueries : "admin/getUserQueries"

}


