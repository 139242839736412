import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Avatar, Grid, IconButton, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
// components
import StyledTableRow from '../../Components/Common/StyledTableRow';
import StyledTableCell from '../../Components/Common/StyledTableCell';
import PaperContainer from '../../Components/Common/StyledTableCell';
import CustomTooltip from '../../Components/Common/CustomTooltip'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddUpdateSubject from '../../Components/Subject/AddUpdateSubject'
import { useDispatch } from 'react-redux';
import ErrorHandler, { trimAndRemoveExtraSpaces } from '../../Utils/handler';
import CommonHeader from '../../Components/Common/CommonHeader';
import CustomDialog from '../../Components/Common/CustomDialog';
import { activeInactiveSubject, createSubject, getSubjectById, getSubjects, updateSubject } from '../../Redux/Slices/subjectSlice';
import ButtonContained from '../../Components/Common/ButtonContained';
import DataNotFound from '../../Components/Common/dataNotFound';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomPagination from '../../Components/Common/CustomPagination';
import { deleteFile, uploadFile } from '../../Redux/Actions/action';
import { BucketURL } from '../../ApiSetUp/api';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import WidgetLoader from '../../Components/Common/widgetLoader';
import { toast } from 'react-toastify';

function Subject() {
    //  Hooks 
    const theme = useTheme();
    const dispatch = useDispatch()

    //  State 
    const [isOpen, setIsOpen] = React.useState(false);
    const [isEdit, setisEdit] = React.useState(false);
    const [subjectDataList, setSubjectDataList] = React.useState({})
    const [errors, setErrors] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState({})
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchText, setSearchText] = React.useState("");
    const [oldSubjectFileUrl, setOldSubjectFileUrl] = React.useState("");

    //  Validations 
    const validation = () => {
        let isValid = true;
        let error = {};

        if (!data?.subjectName) {
            isValid = false;
            error.subjectName = '*Subject name is required';
        }
        if (!data?.subjectFileUrl) {
            isValid = false;
            error.subjectFileUrl = '*Subject image is required';
        }
        setErrors(error);
        return isValid;
    }

    //  Handlers 
    const handleClear = () => {
        setIsOpen(false);
        setisEdit(false);
        setData({});
        setErrors({});
        setOldSubjectFileUrl('')
    }

    console.log(data, 'data')

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    const handelDeleteFile = () => {
        setData(prevState => ({
            ...prevState,
            subjectFileUrl: "",
            subjectFileName: ""
        }));
    }

    const handleImageUpload = (acceptFile) => {
        const fileData = acceptFile?.target?.files?.[0];

        console.log(fileData, URL.createObjectURL(fileData), 'fileData')

        if (fileData) {
            // Check file type
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validTypes.includes(fileData.type)) {
                setErrors(errors => ({
                    ...errors,
                    subjectImageSize: 'Please upload an image file (JPEG, PNG, JPG).',
                    subjectImage: ''
                }));
                return;
            }

            if (fileData?.size < 10485760) {
                setData(prevData => ({
                    ...prevData,
                    fileData: fileData,
                    subjectFileName: fileData?.name,
                    subjectFileUrl: URL.createObjectURL(fileData)
                }))
                setErrors(errors => ({
                    ...errors,
                    subjectImageSize: '',
                }));
            }
            else {
                setErrors(errors => ({
                    ...errors,
                    subjectImageSize: 'File size should be less than 10mb.',
                    subjectImage: ''
                }));
                setData(prevData => ({
                    ...prevData,
                    fileData: null,
                    subjectFileUrl: '',
                    subjectFileName: ''
                }))
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const createUpdateSubject = async () => {
        if (validation()) {
            dispatch(showLoader(true));
            try {
                let body = {};
                if (data?.fileData) {
                    const formData = new FormData();
                    formData.append('image', data?.fileData);

                    const result = await dispatch(uploadFile(formData)).unwrap();
                    if (result.success) {
                        setData(prevState => ({
                            ...prevState,
                            subjectFileUrl: result.data?.data?.image,
                            subjectFileName: result.data?.data?.image?.split("image/")?.[1]
                        }));
                        body.subjectImage = result.data?.data?.image;
                        setErrors(errors => ({
                            ...errors,
                            subjectImageSize: '',
                            subjectFileUrl: ''
                        }));
                    } else {
                        console.error('File upload error:', result.error);
                        return;
                    }
                }

                if (oldSubjectFileUrl && oldSubjectFileUrl !== data?.subjectFileUrl) {
                    _deleteFile(oldSubjectFileUrl)
                }

                body = {
                    "subjectName": trimAndRemoveExtraSpaces(data?.subjectName),
                    "subjectImage": body.subjectImage ? body.subjectImage : data?.subjectFileUrl,
                }
                if (isEdit && data?.id) {
                    body._id = data?.id
                }
                const fn = isEdit ? updateSubject : createSubject;
                const result = await dispatch(fn(body))
                const error = ErrorHandler(result)
                console.log(result, 'result')
                if (error) {
                    toast.success(result?.payload?.message);
                    handleClear()
                    _getSubjects()
                }
            } catch (error) {
                toast.error(error);
            } finally {
                dispatch(showLoader(false));
            }
        }
    }

    const _getSubjects = async (withLoader = true) => {
        if (withLoader) setLoading(true);
        let body = {
            limit: rowsPerPage,
            page: page,
            search: searchText || "",
        };
        try {
            const result = await dispatch(getSubjects(body))
            const error = ErrorHandler(result)
            if (error) {
                setSubjectDataList(result?.payload?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            if (withLoader) setLoading(false);
        }
    }

    const _activeInactiveSubject = async (id, isChecked) => {
        try {
            let body = {
                _id: id,
                isBlock: isChecked,
            };

            const result = await dispatch(activeInactiveSubject(body));
            const error = ErrorHandler(result);
            if (error) {
                console.log(result?.payload?.message, 'message')
                toast.success(result?.payload?.message);
                await _getSubjects(false);
            }
        } catch (error) {
            toast.error(error);
            console.log(error);
        }
    };


    const _getSubjectById = async (id) => {
        dispatch(showLoader(true));
        try {
            const body = `/${id}`
            const result = await dispatch(getSubjectById(body))
            const error = ErrorHandler(result)
            if (error) {
                const subject = result?.payload?.data;
                console.log(subject, "subjectsubject")
                setData({
                    id: subject?._id,
                    subjectName: subject?.subjectName,
                    subjectFileUrl: subject?.subjectImage,
                    subjectFileName: subject?.subjectImage?.split("image/")?.[1],
                });
                setOldSubjectFileUrl(subject?.subjectImage)
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false));
        }
    }


    const _deleteFile = async (fileurl) => {
        try {
            let body = {
                "url": fileurl,
            }
            const result = await dispatch(deleteFile(body))
            const error = ErrorHandler(result)
            if (error) {
                return result
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    //  Effect 
    useEffect(() => {
        _getSubjects()
    }, [rowsPerPage, page, searchText])


    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12}>
                    <CommonHeader
                        title="Subject List"
                        onClick={() => setIsOpen(true)}
                        btnText="Add Subject"
                        handleSearch={(e) => { setPage(1); setSearchText(e); }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer sx={{ width: "100%" }}>
                        {!loading ? (
                            <Table sx={{ minWidth: 700 }}>
                                <TableHead component={PaperContainer} >
                                    <StyledTableRow>
                                        <StyledTableCell align='left'>No.</StyledTableCell>
                                        <StyledTableCell align='left'>Subject Name</StyledTableCell>
                                        <StyledTableCell align="left">Subject Image</StyledTableCell>
                                        <StyledTableCell align="left">Created At</StyledTableCell>
                                        <StyledTableCell align="center">Active/Inactive</StyledTableCell>
                                        <StyledTableCell align="left">Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {subjectDataList?.response?.length > 0 ? subjectDataList?.response?.map((subject, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{subject?.subjectName} </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Avatar srcSet={subject?.subjectImage ? BucketURL + subject?.subjectImage : subject?.subjectName} alt={subject?.subjectImage} slotProps={{ loading: "lazy", }} />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{subject?.createdAt ? new Date(subject.createdAt).toLocaleDateString() : 'N/A'}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Switch
                                                        checked={!subject?.isBlock}  // Show the active/inactive state
                                                        onChange={(e) => { _activeInactiveSubject(subject?._id, !e.target.checked); }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row" sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    <CustomTooltip title={'Edit'}>
                                                        <IconButton size='small' aria-label="edit" sx={{ backgroundColor: theme.palette.bgInfo.secondary, color: theme.palette.bgInfo.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                                            setIsOpen(true);
                                                            setisEdit(true);
                                                            _getSubjectById(subject?._id)
                                                        }}>
                                                            <EditOutlinedIcon fontSize='small' />
                                                        </IconButton>
                                                    </CustomTooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }) :
                                        <StyledTableRow>
                                            <TableCell colSpan={12}>
                                                <DataNotFound title="No Subjects Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                            </TableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        ) : (
                            <WidgetLoader />
                        )}
                    </TableContainer>
                </Grid>
                {subjectDataList?.count > 0 &&
                    <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(subjectDataList?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    </Grid>
                }
            </Grid>
            {isOpen && <CustomDialog
                isOpen={isOpen}
                handleClose={handleClear}
                title={`${isEdit ? "Update" : "Add"} Subject`}
                width={'100%'}
                action={
                    <>
                        <Stack direction="row" justifyContent="flex-end" mr={2} >
                            <ButtonContained height={'35px'} padding={'6px 10px'} text={`${isEdit ? "Update" : "Add"} Subject`} onClick={createUpdateSubject} />
                        </Stack>
                    </>
                }
            >
                <AddUpdateSubject
                    data={data}
                    handleChangeInput={(e) => handleChangeInput(e)}
                    handleImageUpload={handleImageUpload}
                    deleteFile={handelDeleteFile}
                    errors={errors}
                />
            </CustomDialog>
            }
        </>
    )
}

export default Subject
