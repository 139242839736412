import { createSlice } from '@reduxjs/toolkit'


export const loaderSlice = createSlice({
  name: 'loader',
  initialState: { loading: false },
  reducers: {
    showLoader: (state, { payload }) => {
      state.value = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { showLoader } = loaderSlice.actions

export default loaderSlice.reducer