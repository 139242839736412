import { styled } from "@mui/material/styles";
import { TableCell, tableCellClasses } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme?.palette?.bgLightWhite?.main,
    backgroundColor: theme?.palette?.info?.main,
    fontWeight: "700",
    padding: '7px 16px',
    fontSize: 14,
    lineHeight:1.3,
    // Apply border-radius to the first cell in the header
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '3px',
    },

    // Apply border-radius to the last cell in the header
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '3px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '3px 16px',
  },
}));
export default StyledTableCell;
