import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react'
import TextFieldComponent from '../Common/TextFieldComponent';
import FilePreviewModel from '../Common/FilePreviewModel';
import ErrorLog from '../Common/ErrorLog';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function Setting({ data, setData, errors, setErrors }) {
    const [isOpen1, setIsOpen1] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [isOpen3, setIsOpen3] = useState(false)
    const [value, setValue] = React.useState(0);


    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeInput = (e, page) => {
        const { name, value } = e.target;
        if (page === '1') {
            setData(prevState => ({
                ...prevState,
                page1: {
                    ...prevState.page1,
                    [name]: value,
                }
            }));
        }else if(page === '2'){
                setData(prevState => ({
                    ...prevState,
                    page2: {
                        ...prevState.page2,
                        [name]: value,
                    }
                }));
        } else{
                setData(prevState => ({
                    ...prevState,
                    page3: {
                        ...prevState.page3,
                        [name]: value,
                    }
                }));
        }

    };

    const handelDeleteFile = (pageNumber) => {
        if (pageNumber === '1') {
            setData(prevState => ({
                ...prevState,
                page1: {
                    ...prevState.page1,
                    fileUrl: "",
                    fileName: "",
                }
            }));
        } else if (pageNumber === '2') {
            setData(prevState => ({
                ...prevState,
                page2: {
                    ...prevState.page2,
                    fileUrl: "",
                    fileName: "",
                }
            }));
        } else {
            setData(prevState => ({
                ...prevState,
                page3: {
                    ...prevState.page3,
                    fileUrl: "",
                    fileName: "",
                }
            }));
        }
    }

    const handleImageUpload = (acceptFile) => {
        const fileData = acceptFile?.target?.files?.[0];
        const name = acceptFile?.target?.name;
        if (name === 'Image1') {
            if (fileData?.size < 10485760) {
                setData(prevData => ({
                    ...prevData,
                    page1: {
                        ...prevData.page1,
                        fileData: fileData,
                        fileName: fileData?.name,
                        fileUrl: URL.createObjectURL(fileData)
                    }
                }))
                setErrors(errors => ({
                    ...errors,
                    ImageSize1: '',
                }));
            }
            else {
                setErrors(errors => ({
                    ...errors,
                    ImageSize1: 'File size should be less than 10mb.',
                    Image1: ''
                }));
            }
        } else if (name === 'Image2') {
            if (fileData?.size < 10485760) {
                setData(prevData => ({
                    ...prevData,
                    page2: {
                        ...prevData.page2,
                        fileData: fileData,
                        fileName: fileData?.name,
                        fileUrl: URL.createObjectURL(fileData)
                    }
                }))
                setErrors(errors => ({
                    ...errors,
                    ImageSize2: '',
                }));
            }
            else {
                setErrors(errors => ({
                    ...errors,
                    ImageSize2: 'File size should be less than 10mb.',
                    Image2: ''
                }));
            }
        } else {
            if (fileData?.size < 10485760) {
                setData(prevData => ({
                    ...prevData,
                    page3: {
                        ...prevData.page3,
                        fileData: fileData,
                        fileName: fileData?.name,
                        fileUrl: URL.createObjectURL(fileData)
                    }
                }))
                setErrors(errors => ({
                    ...errors,
                    ImageSize3: '',
                }));
            }
            else {
                setErrors(errors => ({
                    ...errors,
                    ImageSize3: 'File size should be less than 10mb.',
                    Image3: ''
                }));
            }
        }
    };

    console.log(data, 'data')

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="landing Page" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography fontWeight={600} >Onboarding Page 1</Typography>
                        <TextFieldComponent
                            text='Title'
                            placeholder='Enter Title'
                            size={"small"}
                            name='title'
                            fullWidth
                            onChange={(e) => {handleChangeInput(e, '1')}}
                            valid
                        />
                        {data?.page1?.title ? "" : <ErrorLog text={errors?.title1} />}
                    </Grid>
                    <Grid item xs={12} >
                        <TextFieldComponent
                            text='Description'
                            placeholder='Enter Description'
                            size={"small"}
                            name='description'
                            fullWidth
                            onChange={(e) => {handleChangeInput(e, '1')}}
                            valid
                        />
                        {data?.page1?.description ? "" : <ErrorLog text={errors?.description1} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FilePreviewModel
                            isOpen={isOpen1}
                            onClick={() => setIsOpen1(true)}
                            handleClose={() => setIsOpen1(false)}
                            id={"image1-upload"}
                            htmlFor={"image1-upload"}
                            accept={".jpeg,.jpg,.png"}
                            name={"Image1"}
                            fileLink={data?.page1?.fileUrl}
                            title={"Image"}
                            btnText={"Upload Image"}
                            label="Upload Image"
                            fileName={data?.page1?.fileName}
                            onChange={handleImageUpload}
                            deleteFile={() => { handelDeleteFile('1') }}
                            data={data?.page1}
                            valid
                        />
                        {data?.page1?.fileUrl ? "" : <ErrorLog text={errors?.Image1} />}
                        {data?.page1?.fileUrl ? "" : <ErrorLog text={errors?.ImageSize1} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography fontWeight={600}>Onboarding Page 2</Typography>
                        <TextFieldComponent
                            text='Title'
                            placeholder='Enter Title'
                            size={"small"}
                            name='title'
                            fullWidth
                            onChange={(e) => {handleChangeInput(e, '2')}}
                            valid
                        />
                        {data?.page2?.title ? "" : <ErrorLog text={errors?.title2} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextFieldComponent
                            text='Description'
                            placeholder='Enter Description'
                            size={"small"}
                            name='description'
                            fullWidth
                            onChange={(e) => {handleChangeInput(e, '2')}}
                            valid
                        />
                        {data?.page2?.description ? "" : <ErrorLog text={errors?.description2} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FilePreviewModel
                            isOpen={isOpen2}
                            onClick={() => setIsOpen2(true)}
                            handleClose={() => setIsOpen2(false)}
                            id={"image2-upload"}
                            htmlFor={"image2-upload"}
                            accept={".jpeg,.jpg,.png"}
                            name={"Image2"}
                            fileLink={data?.page2?.fileUrl}
                            title={"Image"}
                            btnText={"Upload Image"}
                            label="Upload Image"
                            fileName={data?.page2?.fileName}
                            onChange={handleImageUpload}
                            deleteFile={() => { handelDeleteFile('2') }}
                            data={data?.page2}
                            valid
                        />
                        {data?.page2?.fileUrl ? "" : <ErrorLog text={errors?.Image2} />}
                        {data?.page2?.fileUrl ? "" : <ErrorLog text={errors?.ImageSize2} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography fontWeight={600}>Onboarding Page 3</Typography>
                        <TextFieldComponent
                            text='Title'
                            placeholder='Enter Title'
                            size={"small"}
                            name='title'
                            fullWidth
                            onChange={(e) => {handleChangeInput(e, '3')}}
                            valid
                        />
                        {data?.page3?.title ? "" : <ErrorLog text={errors?.title3} />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextFieldComponent
                            text='Description'
                            placeholder='Enter Description'
                            size={"small"}
                            name='description'
                            fullWidth
                            onChange={(e) => {handleChangeInput(e, '3')}}
                            valid
                        />
                        {data?.page3?.description ? "" : <ErrorLog text={errors?.description3} />}
                    </Grid>
                    <FilePreviewModel
                        isOpen={isOpen3}
                        onClick={() => setIsOpen3(true)}
                        handleClose={() => setIsOpen3(false)}
                        id={"image3-upload"}
                        htmlFor={"image3-upload"}
                        accept={".jpeg,.jpg,.png"}
                        name={"Image3"}
                        fileLink={data?.page3?.fileUrl}
                        title={"Image"}
                        btnText={"Upload Image"}
                        label="Upload Image"
                        fileName={data?.page3?.fileName}
                        onChange={handleImageUpload}
                        deleteFile={() => { handelDeleteFile('3') }}
                        data={data?.page3}
                        valid
                    />
                    {data?.page3?.fileUrl ? "" : <ErrorLog text={errors?.Image3} />}
                    {data?.page3?.fileUrl ? "" : <ErrorLog text={errors?.ImageSize3} />}
                </Grid>
            </CustomTabPanel >

        </>
    )
}

export default Setting
