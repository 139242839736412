import React, { useEffect } from 'react';
import { Box, Grid, Skeleton, Typography, useTheme, } from '@mui/material';
// components
import { useDispatch } from 'react-redux';
import ErrorHandler from '../../Utils/handler';
import { getDashboardDetails, } from '../../Redux/Actions/action';
import CommonHeader from '../../Components/Common/CommonHeader';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Person2Icon from '@mui/icons-material/Person2';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import SubjectIcon from '@mui/icons-material/Subject';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
    //Hooks
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //State
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    console.log(data, "data")
    //Handler

    // const handleBoxClick = (title) => {
    //     if (title === 'Total User' || title === 'Total Subscribed User' || title === 'Total Not Subscribed User' || title === 'Total Blocked User') {
    //         navigate('/user')
    //     } else if (title === 'Total Lecture' || title === 'Total Free Lecture' || title === 'Total Paid Lecture') {
    //         navigate('/lecture')
    //     } else if (title === 'Total User Queries') {
    //         navigate('/userQuery')
    //     }
    // }

    const _getDashboardDetails = async (loader = true) => {
        if (loader) setLoading(true)
        try {
            const result = await dispatch(getDashboardDetails())
            console.log(result, 'dashbb')
            const error = ErrorHandler(result)
            if (error) {
                setData(result?.payload?.data?.dashboardDetails)
            }
        } catch (error) {
            console.log(error)
        } finally {
            if (loader) setLoading(false)
        }
    }

    useEffect(() => {
        _getDashboardDetails()
    }, [])

    return (
        <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12}>
                <CommonHeader title="Dashboard" />
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>

                {data?.totalUser || data?.totalUser == 0 ? <DashboardSummaryBox
                    count={data?.totalUser}
                    title={"Total User"}
                    onClick={() => navigate('/user')}
                    avtar={<PeopleAltIcon fontSize='large' color='primary' />}
                /> : <DashboardSummarySkeleton />}
                {data?.totalSubscribedUser || data?.totalSubscribedUser == 0 ? <DashboardSummaryBox
                    count={data?.totalSubscribedUser}
                    title={"Total Subscribed User"}
                    onClick={() => navigate('/user')}
                    avtar={<Person2Icon fontSize='large' color='primary' />}
                /> : <DashboardSummarySkeleton />}
                {data?.totalNotSubscribedUser || data?.totalNotSubscribedUser == 0 ? <DashboardSummaryBox
                    count={data?.totalNotSubscribedUser}
                    title={"Total Not Subscribed User"}
                    onClick={() => navigate('/user')}
                    avtar={<PersonOffIcon fontSize='large' color='primary' />}
                /> : <DashboardSummarySkeleton />}
                {data?.totalBlockUser || data?.totalBlockUser == 0 ? <DashboardSummaryBox
                    count={data?.totalBlockUser}
                    title={"Total Blocked User"}
                    onClick={() => navigate('/user')}
                    avtar={<NoAccountsIcon fontSize='large' color='primary' />}
                /> : <DashboardSummarySkeleton />}
                {data?.totalFreeLecture || data?.totalFreeLecture == 0 ? <DashboardSummaryBox
                    count={data?.totalFreeLecture}
                    title={"Total Free Lecture"}
                    onClick={() => navigate('/lecture')}
                    avtar={<SubjectIcon fontSize='large' color='primary' />}
                /> : <DashboardSummarySkeleton />}
                {data?.totalPaidLecture || data?.totalPaidLecture == 0 ? <DashboardSummaryBox
                    count={data?.totalPaidLecture}
                    title={"Total Paid Lecture"}
                    onClick={() => navigate('/lecture')}
                    avtar={<SubjectIcon fontSize='large' color='primary' />}
                /> : <DashboardSummarySkeleton />}
                {data?.totalUserQueries || data?.totalUserQueries == 0 ? <DashboardSummaryBox
                    count={data?.totalUserQueries}
                    title={"Total User Queries"}
                    onClick={() => navigate('/userQuery')}
                    avtar={<ReceiptLongOutlinedIcon fontSize='large' color='primary' />}
                /> : <DashboardSummarySkeleton />}

            </Grid>
        </Grid>
    )
};
const DashboardSummaryBox = ({ avtar, count, title, onClick }) => {
    // const { classes } = useStyles();
    const theme = useTheme();

    return (
        <Grid item xs={12} sm={6} md={3} lg={3} >
            <Box display={"flex"} justifyContent={'space-between'} flex={'wrap'} borderRadius={2} padding={1} border={`2px dashed ${theme.palette.info.dark}`} sx={{
                ":hover": {
                    cursor: 'pointer', border: `2.5px ridge ${theme.palette.info.main}`,
                }
            }} onClick={onClick}>
                {avtar}
                <Box display={"flex"} gap={1} flexDirection={"column"}>
                    <Typography variant={"body1"} fontWeight={600} textOverflow={"inherit"} color={'primary'} whiteSpace={"wrap"} overflow={"hidden"} >{title}</Typography>
                    <Typography variant={"h5"} fontWeight={600} textAlign={'end'} color={theme.palette.info.main} >{count}</Typography>
                </Box>
            </Box>
        </Grid>
    );
};
const DashboardSummarySkeleton = () => {
    const theme = useTheme();

    return (
        <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                borderRadius={2}
                padding={1}
                border={`2px dashed ${theme.palette.info.dark}`}
                sx={{
                    ":hover": {
                        cursor: 'default',
                        border: `2px ridge ${theme.palette.info.main}`,
                    },
                }}
            >
                <Skeleton variant="text" width={20} height={40} />
                <Skeleton variant="text" width="100%" height={'10%'} />
            </Box>
        </Grid>
    );
};


export default Dashboard
